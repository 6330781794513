.default-search {
    height: 52px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    padding: 15px 10px;
    background-color: #fff;
    border: 1px solid rgba(220, 222, 224, 0.7);

    i {
        padding: 10px;
        background-color: #999;
    }

    input[type='search'] {
        line-height: 18px;
        border: none;
        background-color: transparent !important;
        padding: 10px;
        /*padding-right: 15px;*/
        margin-right: 15px;
        width: 90%;

        &::placeholder {
            color: #47525e;
        }
    }

    .clear-search .btn.btn-link {
        opacity: 1;
        padding: 5px 0 !important;
        -webkit-backface-visibility: hidden;
        -webkit-transition: opacity 100ms cubic-bezier(0.05, 0.74, 0.27, 0.99);
        -moz-transition: opacity 100ms cubic-bezier(0.05, 0.74, 0.27, 0.99);
        -o-transition: opacity 100ms cubic-bezier(0.05, 0.74, 0.27, 0.99);
        transition: opacity 100ms cubic-bezier(0.05, 0.74, 0.27, 0.99);

        &.disabled {
            opacity: 0.1 !important;
            cursor: not-allowed !important;
            pointer-events: none !important;
        }
    }

    &.focused {
        border-color: #0f6baf;
    }
}

@media (min-width: 769px) {
    .mobile-search {
        display: none;
        visibility: hidden;
    }
}

@media (max-width: 768px) {
    .mobile-search {
        display: flex;
        visibility: visible;
        position: relative;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 50px;
        float: right;

        .search-input,
        .search-input:focus,
        .search-input:active {
            background-color: #f3f3f4;
            border: 0;
            font-size: 16px;
            font-weight: normal;
            padding: 15px;
            height: 50px;
            width: 50px;
            transition: width 0.3s ease;
            box-shadow: none;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }

        .search-btn {
            background-color: #f3f3f4;
            border: 0;
            cursor: pointer;
            font-size: 20px;
            position: absolute;
            top: 0;
            left: 0;
            height: 50px;
            width: 50px;
            transition: all 0.3s ease;

            i {
                max-height: 25px;
                max-width: 25px;
            }
        }

        .search-btn:focus,
        .search-input:focus {
            outline: none;
        }

        &.active {
            .search-input {
                width: 350px;
            }

            .search-btn {
                transform: translateX(310px);
            }
        }
    }
}

@media (max-width: 576px) {
    .mobile-search {
        &.active {
            .search-input {
                width: calc(100vw - 15px);
            }

            .search-btn {
                transform: translateX(calc(100vw - 55px));
            }
        }
    }

    .searchContainer.active .searchInput {
        width: calc(100vw - 15px);
    }
    .searchContainer.active .searchBtn {
        transform: translateX(calc(100vw - 55px));
    }
}
