@charset "UTF-8";
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600');
@import url('https://keplerapis.com/fonts/css?family=Inter+UI:300,400,500,600');

@import '../css/bootstrap.min.css';

@import 'typography';
@import 'tooltip';
@import 'custom_icons';
@import 'overrides';

.v-divider {
    display: inline-block;
    background-color: rgba(130, 130, 130, 0.25);
    height: 15px;
    width: 1px;
    cursor: none;
    margin: 0 15px;
}
