.page-transition,
.component-loading,
.full-screen-upload-progress {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1055;
    background-color: #f3f3f4;
    display: flex;
    justify-content: center;
    align-items: center;
}

.full-screen-upload-progress {
    position: absolute !important;
    background-color: rgba(243, 243, 244, 0.85) !important;
}

.component-loading {
    position: relative;
    z-index: inherit;
    flex-direction: column;
    background-color: transparent !important;
}

@keyframes three-sixty-rotation {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}

.page-transition .loading,
.component-loading .loading,
.full-screen-upload-progress .loading {
    position: relative;
    width: 80px;
    height: 80px;
}

.page-transition .loading span,
.component-loading .loading span,
.full-screen-upload-progress .loading span {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-width: 5px;
    border-style: solid;
    border-radius: 50%;
    animation: three-sixty-rotation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #78b94c transparent transparent transparent;
}

.component-loading .loading {
    position: relative;
    width: 60px;
    height: 60px;
}

.component-loading .loading-text {
    position: relative;
    text-align: center;
}

.component-loading .loading span {
    width: 44px;
    height: 44px;
    border-width: 3px;
}

.page-transition .loading span:nth-child(1),
.component-loading .loading span:nth-child(1) {
    animation-delay: -0.45s;
}

.page-transition .loading span:nth-child(2),
.component-loading .loading span:nth-child(2) {
    animation-delay: -0.3s;
}

.page-transition .loading span:nth-child(3),
.component-loading .loading span:nth-child(3) {
    animation-delay: -0.15s;
}

.loading-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    min-height: 100vh;
    z-index: 1055;
    background-color: #f3f3f4;
}

.loading-wrapper .progress-indicator-container {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.loading-wrapper .progress-indicator-container.loading-text {
    padding: 0;
    text-align: center;
}
.loading-wrapper .progress-indicator-container::after {
    content: '';
    position: absolute;
    border-radius: 100%;
    width: 48px;
    height: 48px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border: 3px solid rgba(0, 0, 0, 0.25);
    border-left-color: #000;
    border-top-color: #000;
    animation: three-sixty-rotation 0.35s infinite linear;
    opacity: 1;
}
