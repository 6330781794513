/*.card {
    background-color: #fff;
    font-family: -apple-system, BlinkMacSystemFont, 'Inter UI', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    position: relative;
    border: 1px solid rgba(6, 18, 35, 0.14);
    border-top-color: rgba(0, 0, 0, 0.14);
    border-radius: 10px;
    padding: 20px;
    margin: 20px 0 5px 0;
    overflow: hidden;
    width: 100%;
    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease;
    cursor: auto;

    &.labeled {
        overflow: visible;

        .card-label {
            position: absolute;
            top: -12px;
            left: 15px;
            padding: 0 5px;
            background-color: #ffffff;
            font-family: -apple-system, BlinkMacSystemFont, 'Inter UI', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
            'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
            font-size: 16px;
        }
    }

    &.subtle {
        padding: 0;
        margin: 0;
        border: 0;

        &.labeled {
            .card-label {
                display: none;
                visibility: hidden;
            }
        }
    }
}*/

.labeled-card {
    background-color: #fff;
    font-family: -apple-system, BlinkMacSystemFont, 'Inter UI', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    position: relative;
    border: 1px solid rgba(6, 18, 35, 0.14);
    border-top-color: rgba(0, 0, 0, 0.14);
    border-radius: 10px;
    padding: 20px;
    margin: 20px 0 5px 0;
    overflow: visible;
    width: 100%;
    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease;
    cursor: auto;

    .card-label {
        position: absolute;
        top: -12px;
        left: 15px;
        padding: 0 5px;
        background-color: #ffffff;
        font-family: -apple-system, BlinkMacSystemFont, 'Inter UI', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
            'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
        font-size: 16px;
    }

    &.static-fields {
        border-radius: 2px;
        padding: 15px 20px;

        .card-label {
            top: -10px;
            font-size: 13px;
        }
    }

    &.subtle {
        padding: 0;
        margin: 0;
        border: 0;

        .card-label {
            display: none;
            visibility: hidden;
        }
    }
}
