* {
    box-sizing: border-box;
}

*:before,
*:after {
    box-sizing: border-box;
}

html,
body {
    height: auto;
    position: relative;
}

body {
    margin: 0;
    padding: 0;
    background-color: #f3f3f4 !important;
    direction: ltr;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    overflow-x: hidden;
}

header {
    position: fixed;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 60px !important;
    width: calc(100% - 80px) !important;
    left: 80px;
    padding: 0 35px;
    z-index: 1055 !important;
    background-color: #f3f3f4;
    border-bottom: 1px solid rgba(220, 222, 224, 0.7);
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

header .header-inner,
header .header-inner .header-left,
header .header-inner .header-right {
    height: 100%;
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

header {
    @media (max-width: 992px) {
        width: 100% !important;
        left: 0;
        padding: 0 15px !important;
    }

    @media (max-width: 768px) {
        padding: 0 10px !important;
    }

    .header-inner {
        .header-left {
            width: 15%;
            flex: 1;
            align-items: center;
            justify-content: flex-start;
            display: none;
            visibility: hidden;

            .hamburger-react {
                display: none;
                visibility: hidden;

                @media (max-width: 992px) {
                    display: block;
                    visibility: visible;
                }
            }
        }

        .header-right {
            flex: 1;
            width: 100%;
            justify-content: flex-end;

            div {
                margin: 0 20px 0 0;

                &.btn-divider {
                    background-color: rgba(130, 130, 130, 0.25);
                    height: 15px;
                    width: 1px;
                    cursor: none;
                }

                &:last-of-type {
                    margin: 0;
                }
            }

            @media (max-width: 768px) {
                width: 70%;
            }
        }
    }
}

.child-page {
    left: 0;
    width: 100%;
    &-header {
        position: fixed;
        z-index: 800;
        left: 0;
        top: 0;
        width: 100%;
        height: 70px;
        background-color: rgba(243, 243, 244, 0.85);
        border-bottom: 1px solid rgba(220, 222, 224, 0.7);

        .header-inner {
            padding: 10px 20px !important;
            width: 100%;
            height: 100%;
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            div {
                width: 33.333333333%;
                height: 100%;
                display: flex;
                align-items: center;

                &:first-child {
                    justify-content: flex-start;
                    @media (max-width: 576px) {
                        display: none;
                        visibility: hidden;
                    }
                }

                &.title-divider {
                    width: 1px;
                    background-color: rgba(220, 222, 224, 0.7);
                    margin: 0 10px;
                    display: none;
                    visibility: hidden;

                    @media (max-width: 576px) {
                        display: none;
                        visibility: hidden;
                    }
                }

                &:last-child {
                    justify-content: flex-end;

                    a {
                        outline: 0;

                        &:hover,
                        &:active,
                        &:focus {
                            text-decoration: none;
                        }
                    }
                }

                &.header-title {
                    flex-direction: column;
                    justify-content: center;

                    h4,
                    p {
                        margin: 0;
                    }

                    @media (max-width: 768px) {
                        h4 {
                            font-size: 16px;
                        }
                    }

                    @media (max-width: 576px) {
                        flex: 1;
                        justify-content: flex-start;
                        align-items: flex-start;
                    }

                    @media (max-width: 375px) {
                        width: 70%;
                    }
                }
            }
        }
    }

    .content {
        margin: 110px 0;
    }
}

/*  Auth page wrapper
---------------------------------*/
.auth-wrapper {
    width: 350px;
    margin: 80px auto 0 auto;

    form {
        margin: 10px 0 30px 0;
    }

    @media (max-device-width: 768px) {
        padding: 0;
        background-color: transparent;
        border: none;
    }

    @media (max-device-width: 500px) {
        padding: 10px;
        width: 100%;
    }

    .auth-wrapper-header {
        width: 100%;
        margin: 30px 0;
        padding: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        div {
            padding: 0;
        }
    }

    .auth-wrapper-body {
        /*background-color: #fff;*/
        border-bottom: 1px solid #e6e6e6;
    }

    .auth-wrapper-footer {
        margin-top: 10px;
        margin-bottom: 30px;

        &.below-form {
            margin: 30px 0;
        }
    }
}

.btn {
    font-weight: 500;
    font-size: 0.85rem !important;
    padding: 0.65rem 1.25rem !important;
    border-radius: 0.125rem !important;
}

.sidebar {
    min-height: 100vh;
    width: 80px;
    border-right: 1px solid rgb(230, 230, 230);
    background-color: #ffffff;
    z-index: 1055;
    position: fixed;

    .sidebar-inner {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        .logo {
            width: 100%;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 15px;
        }

        nav {
            height: 100%;

            a {
                /*width: 100%;*/
                display: block;
                width: 48px;
                height: 48px;
                border-radius: 50%;
                background-color: transparent;
                padding: 12px;
                margin-bottom: 25px;
                font-weight: 400;
                outline: 0;
                color: rgba(0, 0, 0, 0.65);
                transition: all 400ms cubic-bezier(0.05, 0.74, 0.27, 0.99);
                border: 1px solid transparent;

                span {
                    display: none;
                    visibility: hidden;
                    padding: 0 25px;
                    font-size: 1.025rem;
                }

                &:focus,
                &:active,
                &:hover,
                &.active {
                    text-decoration: none;
                    color: rgba(0, 0, 0, 1);
                    background-color: #f3f3f4;
                    border: 1px solid rgb(230, 230, 230);
                }
            }

            .nav-divider {
                margin: 0 auto 35px auto;
                background-color: rgb(230, 230, 230);
                height: 1px;
                width: 24px;
                cursor: none;
            }
        }
    }
}

.page-content-container {
    min-height: 100%;
    position: relative;
    @media (min-width: 992px) {
        left: 80px;
        width: calc(100% - 80px);
    }
    @media (max-width: 768px) {
        min-height: 100vh;
    }

    &.child-page {
        left: 0 !important;
        width: 100% !important;
    }

    .push-container {
        width: 100%;
        height: 100%;

        -webkit-backface-visibility: hidden;
        -webkit-transition: -webkit-transform 400ms cubic-bezier(0.05, 0.74, 0.27, 0.99);
        -moz-transition: -moz-transform 400ms cubic-bezier(0.05, 0.74, 0.27, 0.99);
        -o-transition: -o-transform 400ms cubic-bezier(0.05, 0.74, 0.27, 0.99);
        transition: transform 400ms cubic-bezier(0.05, 0.74, 0.27, 0.99);

        .content {
            padding: 80px 25px 0 25px;
            width: inherit;
            min-height: 100vh;
            position: relative;
            @media (max-width: 768px) {
                display: flex;
                flex-direction: column;
                flex: 1;
                align-items: center;
                padding: 80px 10px 0 10px !important;
            }

            .full-width {
                width: 100% !important;
            }

            .full-height {
                height: 100% !important;
            }
        }
    }
}

@media (max-width: 992px) {
    header {
        .header-inner {
            .header-left {
                display: flex;
                visibility: visible;
            }
        }
    }

    .sidebar {
        position: fixed;
        width: 300px;
        top: 60px;
        left: -300px;
        bottom: 0;
        overflow: auto;
        -webkit-transition: -webkit-transform 400ms cubic-bezier(0.05, 0.74, 0.27, 0.99);
        -moz-transition: -moz-transform 400ms cubic-bezier(0.05, 0.74, 0.27, 0.99);
        -o-transition: -o-transform 400ms cubic-bezier(0.05, 0.74, 0.27, 0.99);
        transition: transform 400ms cubic-bezier(0.05, 0.74, 0.27, 0.99);
        -webkit-backface-visibility: hidden;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        -ms-transform: translate(0, 0);

        .sidebar-inner {
            padding: 0 25px;

            .logo {
                display: none;
                visibility: hidden;
            }

            nav {
                width: 100%;

                a {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    border-width: 0;
                    border-radius: 0;
                    border-bottom: 1px solid rgba(230, 230, 230, 0.75);
                    padding: 35px 0;
                    margin: 0;

                    &:last-of-type {
                        border: none;
                    }

                    span {
                        display: block;
                        visibility: visible;
                    }

                    &:focus,
                    &:active,
                    &:hover,
                    a.active {
                        background-color: transparent;
                        border: none;
                        border-bottom: 1px solid rgba(230, 230, 230, 1);

                        &:last-of-type {
                            border: none;
                        }
                    }

                    &.tooltip-top,
                    &.tooltip-right,
                    &.tooltip-bottom,
                    &.tooltip-left {
                        &:before,
                        &:after {
                            display: none;
                            visibility: hidden;
                        }
                    }
                }

                footer {
                    margin: 15px 0 0 0;
                }

                .nav-divider {
                    display: none;
                    visibility: hidden;
                }
            }
        }
    }

    .page-content-container {
        width: 100%;
        left: 0;
    }

    .sidebar-open {
        .sidebar {
            -webkit-transform: translate3d(300px, 0, 0);
            transform: translate3d(300px, 0, 0);
            -ms-transform: translate(300px, 0);
        }

        .page-content-container {
            .push-container {
                overflow: hidden;
                -webkit-transform: translate3d(300px, 0, 0);
                transform: translate3d(300px, 0, 0);
                -ms-transform: translate(300px, 0);
            }
        }

        & + .backdrop {
            opacity: 0.5;
            display: block;
        }
    }
}

@media (max-width: 576px) {
    .sidebar {
        width: 100%;
        left: -100%;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        -ms-transform: translate(0, 0);
    }

    .sidebar,
    .page-content-container .push-container {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        -ms-transform: translate(0, 0);
    }

    .sidebar-open {
        .sidebar,
        .page-content-container .push-container {
            -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
            -ms-transform: translate(100%, 0);
        }
    }
}

.error-page {
    height: 100vh;
    min-width: 100%;
    background-color: #ffffff;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    overflow: hidden;

    div {
        width: 100%;

        &.branding {
            max-height: 10%;
        }

        &.error-video {
            height: 60%;
            display: block;

            video {
                width: 50%;
                margin: 0 auto;
            }
        }

        &.error-body-container {
            height: 40%;
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            font-weight: normal;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .error-body {
                width: inherit;
                height: 70%;
                font-size: 1rem;
                margin: 15px;

                h2 {
                    font-size: 1rem;
                    line-height: 1.125rem;
                }
            }

            .error-footer {
                width: inherit;
                height: inherit;
                padding: 30px 0;
                border-top: 1px solid rgba(230, 230, 230, 1);
                font-size: 13px;

                a {
                    text-decoration: underline;

                    &:hover,
                    &:active,
                    &:focus {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}
