.dropdown-menu {
    position: absolute;
    display: none;
    float: left;
    list-style: none;
    text-shadow: none;
    max-width: calc(100vw - 24px);
    box-shadow: rgba(15, 15, 15, 0.05) 0 0 0 1px, rgba(15, 15, 15, 0.1) 0 3px 6px, rgba(15, 15, 15, 0.2) 0 9px 24px;
    border: none;
    padding-top: 4px;
    padding-bottom: 4px;
    border-radius: 3px;
    font-size: 13px;
    margin: 0;
    background: #fff;
    min-width: initial;
    z-index: 700 !important;
}

.dropdown-menu .divider {
    background-color: #dcdee0;
    height: 1px;
    margin: 3px 0;
    border-bottom: 0;
}

.dropdown-menu > li,
.dropdown-menu > .dropdown-item {
    height: 100%;
    line-height: 25px;
    color: #353f4d;
    padding: 0 16px;
    border-radius: 0;
    font-size: 13px;
    clear: both;
    white-space: nowrap;
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.dropdown-menu > li:hover,
.dropdown-menu > li:focus,
.dropdown-menu > li:active,
.dropdown-menu > .dropdown-item:hover,
.dropdown-menu > .dropdown-item:focus,
.dropdown-menu > .dropdown-item:active {
    background-color: transparent;
}

.dropdown-menu > li.dropdown-header,
.dropdown-menu > .dropdown-item.dropdown-header {
    padding: 3px 20px;
}

.dropdown-menu > li.active > a,
.dropdown-menu > li.active > a:hover,
.dropdown-menu > li.active > a:focus,
.dropdown-menu > li.active,
.dropdown-menu > li.active:hover,
.dropdown-menu > li.active:focus,
.dropdown-menu > .dropdown-item.active > a,
.dropdown-menu > .dropdown-item.active > a:hover,
.dropdown-menu > .dropdown-item.active > a:focus,
.dropdown-menu > .dropdown-item.active,
.dropdown-menu > .dropdown-item.active:hover,
.dropdown-menu > .dropdown-item.active:focus {
    color: #061223;
    text-decoration: none;
    background-color: #f3f3f4 !important;
    border-radius: 0;
}

.dropdown-menu > li.disabled > a,
.dropdown-menu > li.disabled > a:hover,
.dropdown-menu > li.disabled > a:focus,
.dropdown-menu > li.disabled,
.dropdown-menu > li.disabled:hover,
.dropdown-menu > li.disabled:focus,
.dropdown-menu > .dropdown-item.disabled > a,
.dropdown-menu > .dropdown-item.disabled > a:hover,
.dropdown-menu > .dropdown-item.disabled > a:focus,
.dropdown-menu > .dropdown-item.disabled,
.dropdown-menu > .dropdown-item.disabled:hover,
.dropdown-menu > .dropdown-item.disabled:focus {
    color: #5f7089;
}

.dropdown-menu > li.disabled > a:hover,
.dropdown-menu > li.disabled > a:focus,
.dropdown-menu > li.disabled:hover,
.dropdown-menu > li.disabled:focus,
.dropdown-menu > .dropdown-item.disabled > a:hover,
.dropdown-menu > .dropdown-item.disabled > a:focus,
.dropdown-menu > .dropdown-item.disabled:hover,
.dropdown-menu > .dropdown-item.disabled:focus {
    text-decoration: none;
    cursor: default;
}

.dropdown-menu > a > i {
    margin-right: 5px;
}

.inline {
    display: inline-block !important;
}

.circular {
    border-radius: 50% !important;
}

.circular.d32 {
    border-radius: 16px !important;
}

.dropdown-menu > li > a,
.dropdown-menu > .dropdown-item > a {
    line-height: 35px;
    color: #353f4d;
    padding: 0 20px;
    border-radius: 3px;
    display: block;
    clear: both;
    white-space: nowrap;
    text-align: left;
}

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus,
.dropdown-menu > .dropdown-item > a:hover,
.dropdown-menu > .dropdown-item > a:focus {
    color: #061223;
    text-decoration: none;
    background-color: transparent;
    background-image: none;
}

.dropdown-menu > a.dropdown-item:hover,
.dropdown-menu > a.dropdown-item:focus,
.dropdown-menu > a.dropdown-item:active {
    color: #061223;
    text-decoration: none;
    background-color: #d2f0ff;
}

.dropdown-menu > li {
    padding: 0;
}

.dropdown-menu > a {
    line-height: 35px;
    color: #353f4d;
    padding: 0 20px;
    border-radius: 3px;
    display: block;
    clear: both;
    white-space: nowrap;
    text-align: left;
}

.dropdown-menu > a:hover,
.dropdown-menu > a:focus {
    color: #061223;
    text-decoration: none;
    background-color: transparent;
    background-image: none;
}

.dropdown-menu > li {
    padding: 0;
}

.dropdown-backdrop {
    z-index: 600;
}

.profile-dropdown-toggle {
    padding: 0;
    margin-top: 0 !important;
    margin-right: 0 !important;
    border-radius: 50%;
    transition: all cubic-bezier(0.4, 0, 0.2, 1) 180ms;
}

.profile-dropdown-toggle {
    &:after {
        border: none !important;
        margin: 0 !important;
    }

    &.cart-toggle {
        position: relative;

        &.cart-count:after {
            content: attr(data-qty);
            position: absolute;
            border-radius: 100%;
            height: 18px;
            width: 18px;
            background-color: #000;
            color: #fff;
            padding: -2px 0 2px 0;
            font-size: 0.85rem;
            font-weight: 300;
            top: -3px;
            right: -5px;
        }
    }

    &.dropdown-toggle:after {
        .update-icon {
            position: absolute;
            border-radius: 100%;
            height: 16px;
            width: 16px;
            background-color: #007be8;
            bottom: -5px;
            right: -5px;
            border: 2px solid #fff;
            opacity: 0.98;
        }
    }
}

.profile-dropdown-toggle:hover {
    box-shadow: 0 0 0 4px rgba(6, 18, 35, 0.14);
}

.profile-dropdown-toggle.active,
.profile-dropdown-toggle:active {
    box-shadow: 0 0 0 4px rgba(101, 108, 119, 0.45);
}

.profile-dropdown {
    background: #fff;
}

.profile-dropdown:before {
    position: absolute;
    top: -4px;
    right: 8px;
    display: inline-block;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #ccc;
    border-left: 7px solid transparent;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    content: '';
}

.profile-dropdown:after {
    position: absolute;
    top: -3px;
    right: 9px;
    display: inline-block;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fff;
    border-left: 6px solid transparent;
    content: '';
}

.profile-dropdown > a {
    -webkit-transition: opacity ease 0.3s;
    transition: opacity ease 0.3s;
    padding-left: 17px;
    padding-right: 37px;
    min-width: 180px;
}

.profile-dropdown > a > i {
    margin-right: 5px;
}

.profile-dropdown > a:hover {
    opacity: 1;
}

.profile-dropdown-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: none;
    border-radius: 50% !important;
    height: 30px;
    width: 30px;
}

.profile-dropdown-toggle .profile-img-wrapper {
    float: left;
    height: inherit;
    overflow: hidden;
    width: inherit;
    -webkit-flex: 1;
    -moz-flex: 1;
    flex: 1;
}

.dropdown-item.logout {
    justify-content: space-between;
}
