$breakpoints: (
    xs: 480px,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1600px,
    xlst: 1920px,
);

$max-breakpoints: (
    xs: 479px,
    sm: 575px,
    md: 767px,
    lg: 991px,
    xl: 1199px,
    xxl: 1599px,
);
@mixin large-display {
    @media (max-width: 1200px) {
        @content;
    }
}

@mixin medium-display {
    @media (max-width: 992px) {
        @content;
    }
}

@mixin tablet-size {
    @media (max-width: 768px) {
        @content;
    }
}

@mixin phone-size {
    @media (max-width: 576px) {
        @content;
    }
}

@keyframes shake {
    8%,
    41% {
        -webkit-transform: translateX(-4px);
    }
    25%,
    58% {
        -webkit-transform: translateX(4px);
    }
    75% {
        -webkit-transform: translateX(-2px);
    }
    92% {
        -webkit-transform: translateX(2px);
    }
    0%,
    100% {
        -webkit-transform: translateX(0);
    }
}

.data-list-item-wrapper {
    font-family: inherit;
    font-size: 15px;
    font-weight: normal;
    line-height: 16px;
    position: relative;
    width: 100% !important;
    min-height: 60px;
    margin: 0 0 5px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.025);
    border: 1px solid rgba(0, 0, 0, 0.045);
    border-radius: 4px;
    -webkit-backface-visibility: hidden;
    -webkit-transition: all 400ms cubic-bezier(0.05, 0.74, 0.27, 0.99);
    -moz-transition: all 400ms cubic-bezier(0.05, 0.74, 0.27, 0.99);
    -o-transition: all 400ms cubic-bezier(0.05, 0.74, 0.27, 0.99);
    transition: all 400ms cubic-bezier(0.05, 0.74, 0.27, 0.99);

    .header {
        min-height: 60px;
        width: 100%;
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: flex-start;

        [class^='col-'] {
            flex-shrink: 2;
            padding: 0 9px 4px;

            .form-check {
                padding: 15px 0 0 15px;
            }

            &:not(.action-col) {
                float: left;
                -webkit-text-stroke: inherit;
                /*padding: 20px 9px 4px;*/
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            &.action-col {
                display: flex;
                flex-flow: row;
                align-items: center;
                justify-content: flex-end;
            }
        }

        .dropDownOpen {
            z-index: 5;
        }
    }

    .body {
        width: 100%;
        min-height: 0;
        padding: 10px 35px;
        display: none;

        .content-wrapper {
            width: 100%;
            height: 100%;
        }

        &.with-tabs {
            padding: 10px 15px;
        }
    }

    .title {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: flex-start;

        h2 {
            font-size: 16px;
            line-height: 18px;
            letter-spacing: -0.05em;
            margin: 0;
            padding: 0;

            small {
                display: block;
                font-size: 12px;
                margin: 3px 0;
                letter-spacing: -0.025em;
            }
        }

        &.text-right {
            justify-content: flex-end;
        }

        div:first-child {
            padding-right: 10px;
            margin-right: 10px;
            border-right: 1px solid rgb(53, 63, 77);
        }

        div:last-child {
            padding-right: 0;
            margin-right: 0;
            border-right: none;
        }
    }

    .list-icon {
        padding: 0 !important;
        border: 1px inset rgb(53, 63, 77, 0.25) !important;
        border-radius: 50%;
        height: 52px;
        width: 52px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #ffffff;

        .icon-success {
            background-color: #78b94c;
        }

        .icon-danger {
            background-color: #ea2c54;
        }

        .icon-warning {
            background-color: #ba8b00;
        }

        .icon-primary {
            background-color: #0973b9;
        }
    }

    .col-divider,
    .btn-divider {
        width: 1px;
        min-height: 42px;
        padding: 10px 0;
        margin: 0 10px;
        display: inline-block;
        background-color: rgba(82, 84, 84, 0.45);

        &.light {
            background-color: rgba(82, 84, 84, 0.25);
        }
    }

    @media (max-width: 992px) {
        .filter-open-hide {
            display: none;
            visibility: hidden;
        }
    }

    &:hover,
    &:focus,
    &:active,
    &.selected,
    &.active {
        background-color: rgba(0, 0, 0, 0.045);
        border: 1px solid rgba(0, 0, 0, 0.065);
        box-shadow: 2px 4px 2px -2px rgba(31, 31, 31, 0.35);
    }

    &.success {
        border-color: rgba(40, 167, 69, 0.065) !important;
        background-color: rgba(40, 167, 69, 0.045) !important;

        &:hover,
        &:focus,
        &:active,
        &.selected,
        &.active {
            background-color: rgba(25, 105, 44, 0.085) !important;
            border: 1px solid rgba(25, 105, 44, 0.105) !important;
        }
    }

    &.warning {
        border-color: rgba(255, 193, 7, 0.065) !important;
        background-color: rgba(255, 193, 7, 0.045) !important;

        &:hover,
        &:focus,
        &:active,
        &.selected,
        &.active {
            background-color: rgba(186, 139, 0, 0.085) !important;
            border: 1px solid rgba(186, 139, 0, 0.105) !important;
        }
    }

    &.primary {
        border-color: rgba(9, 115, 185, 0.065) !important;
        background-color: rgba(9, 115, 185, 0.045) !important;

        &:hover,
        &:focus,
        &:active,
        &.selected,
        &.active {
            background-color: rgba(2, 100, 164, 0.085) !important;
            border: 1px solid rgba(2, 100, 164, 0.105) !important;
        }
    }

    &.danger {
        /*color: #ea2c54;*/
        border-color: rgba(220, 53, 69, 0.065) !important;
        background-color: rgba(220, 53, 69, 0.045) !important;

        animation-name: shake;
        animation-duration: 0.4s;
        animation-iteration-count: 1;

        /*i.custom-icon.icon {
            background-color: #ea2c54 !important;
        }

        .title {
            div:first-child {
                border-right: 1px solid rgb(220, 53, 69,);
            }
        }
        */
        &:hover,
        &:focus,
        &:active,
        &.selected,
        &.active {
            background-color: rgba(167, 29, 42, 0.085) !important;
            border: 1px solid rgba(167, 29, 42, 0.105) !important;
        }
    }

    .dropdown {
        display: inline-block !important;
    }

    /*.dropdown-toggle.btn {
        width: 10px;
        padding: 0 10px !important;
    }*/
    .dropdown-toggle::after {
        border: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        vertical-align: 0;
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-more-vertical'%3E%3Ccircle cx='12' cy='12' r='1'%3E%3C/circle%3E%3Ccircle cx='12' cy='5' r='1'%3E%3C/circle%3E%3Ccircle cx='12' cy='19' r='1'%3E%3C/circle%3E%3C/svg%3E");
    }

    .dropdown-menu {
        min-width: 200px;
        margin: 0;
        padding: 0;
    }

    .dropdown-divider {
        margin: 0;
    }

    .dropdown-menu > li,
    .dropdown-menu > .dropdown-item {
        justify-content: space-between;
        line-height: 25px;
        padding: 10px 15px;
        margin: 0;
        font-size: 14px;
    }

    .btn {
        z-index: 2 !important;

        &:after {
            border: none;
            margin: 0;
        }

        &.btn-link {
            min-height: 52px;
            box-sizing: border-box;
            padding: 10px 15px;
            -webkit-transition: all 0.2s ease;
            transition: all 0.2s ease;
            border: 1px solid transparent;
            vertical-align: middle;
            color: #353f4d;
            font-size: 11px;
            font-weight: normal;

            i,
            img,
            svg {
                font-size: 18px;
                margin-right: 10px;
            }

            &:hover,
            &:active,
            &:focus {
                border-radius: 4px !important;
                -webkit-border-radius: 4px !important;
                -moz-border-radius: 4px !important;
                /*border: 1px solid rgba(0, 0, 0, 0.045);*/
                text-decoration: none;

                i.custom-icon.icon {
                    background-color: #000 !important;
                }
            }

            &.add-to-cart {
                color: #ffffff !important;
                background-color: #78b94c !important;
                padding: 10px 15px;
                border: 1px solid #78b94c !important;
                /*border: 1px solid rgba(0, 0, 0, 0.075);*/
                i.custom-icon.icon {
                    background-color: #ffffff !important;
                }

                &.cart-count:after {
                    border-color: #ffffff;
                }

                &:hover,
                &:focus,
                &:active,
                &.inProcess {
                    color: #353f4d !important;
                    background-color: rgba(0, 0, 0, 0.045) !important;
                    border: 1px solid rgba(0, 0, 0, 0.065) !important;

                    i.custom-icon.icon {
                        background-color: #353f4d !important;
                    }

                    &.cart-count:after {
                        border-color: transparent;
                        background-color: #78b94c;
                        color: #fff;
                    }
                }
            }

            &.delete-btn {
                color: #ea2c54 !important;
                -webkit-transition: all 0.2s ease;
                transition: all 0.2s ease;
                border: 1px solid transparent !important;

                i.custom-icon.icon {
                    background-color: #ea2c54 !important;
                }

                &:hover,
                &:focus,
                &:active,
                &.inProcess {
                    color: #ffffff !important;
                    background-color: #ea2c54 !important;
                    border: 1px solid #ea2c54 !important;

                    i.custom-icon.icon {
                        background-color: #ffffff !important;
                    }
                }
            }

            &.upload-btn {
                color: #0973b9 !important;
                border: 1px solid #0973b9 !important;
                -webkit-transition: all 0.2s ease;
                transition: all 0.2s ease;

                i.custom-icon.icon {
                    background-color: #0973b9 !important;
                }

                &:hover,
                &:focus,
                &:active,
                &.inProcess {
                    color: #ffffff !important;
                    background-color: #0973b9 !important;

                    i.custom-icon.icon {
                        background-color: #ffffff !important;
                    }
                }
            }

            &.no-text {
                i,
                img,
                svg {
                    margin-right: 0;
                }
            }

            &.disabled {
                color: rgba(0, 0, 0, 0.25) !important;
                cursor: none !important;
            }

            &.btn-sm {
                height: 36px !important;
                width: 48px !important;
                padding: 5px 10px !important;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        &.dropdown-toggle {
            padding: 16px 15px 15px 15px;
        }
    }

    &.active {
        height: auto;
        /*max-height: 500px;*/

        .header {
            box-shadow: 0 2px 3px rgba(0, 0, 0, 0.12);
        }

        .body {
            min-height: 100px;
            display: block;

            .header {
                box-shadow: none;
            }
        }
    }

    &.tiny {
        min-height: 0 !important;
        height: 40px !important;
        font-size: 13px;
        font-weight: normal;
        line-height: 14px;
        background-color: transparent;
        border-color: transparent;
        border-radius: 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.065);

        &:last-of-type {
            border-bottom-color: transparent;
        }

        .header {
            height: 40px !important;
            box-shadow: none;
        }

        .btn {
            padding: 2px 5px !important;

            &.btn-link {
                min-height: 0;
                height: 30px;
                box-sizing: border-box;
                padding: 0;
                font-size: 13px;

                i,
                img,
                svg {
                    font-size: 12px;
                }
            }
        }

        &:hover,
        &:focus,
        &:active {
            background-color: rgba(0, 0, 0, 0.045);
            border: 1px solid rgba(0, 0, 0, 0.065);
            box-shadow: none;
            border-radius: 4px;
        }
    }
}
