.option-card {
    box-sizing: border-box;
    font-family: inherit;
    font-size: 15px;
    font-weight: normal;
    line-height: 16px;
    position: relative;
    width: 100%;
    min-height: 60px;
    margin: 0 0 10px 0 !important;

    input[type='radio'],
    input[type='checkbox'] {
        position: absolute;
        display: block;
        outline: none;
        border: none;
        background: none;
        padding: 0;
        margin: 0;
        -webkit-appearance: none;
    }

    &-body {
        width: 100%;
        height: 60px;
        padding: 0 0 0 30px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        background-color: rgba(0, 0, 0, 0.025);
        border: 1px solid rgba(0, 0, 0, 0.045);
        border-radius: 4px;
        cursor: pointer;
        -webkit-backface-visibility: hidden;
        -webkit-transition: all 400ms cubic-bezier(0.05, 0.74, 0.27, 0.99);
        -moz-transition: all 400ms cubic-bezier(0.05, 0.74, 0.27, 0.99);
        -o-transition: all 400ms cubic-bezier(0.05, 0.74, 0.27, 0.99);
        transition: all 400ms cubic-bezier(0.05, 0.74, 0.27, 0.99);

        &:before {
            content: '';
            position: absolute;
            height: 16px;
            width: 16px;
            background: transparent;
            left: 20px;
            top: 45%;
            border-radius: 100%;
            border: 3px solid #fff;
            -webkit-box-shadow: 0 0 0 2px #cccccc;
            box-shadow: 0 0 0 2px #cccccc;
            transition: 150ms;
            transition-delay: 75ms;
        }

        &-content {
            display: flex;
            flex-flow: row;
            align-items: center;
            justify-content: flex-start;

            h2 {
                font-size: 16px;
                line-height: 16px;
                letter-spacing: -0.05em;
                margin: 0;
                padding: 0 25px;

                small {
                    display: block;
                    font-size: 12px;
                    margin-top: 5px;
                    letter-spacing: -0.025em;
                }
            }

            &.text-right {
                justify-content: flex-end;
            }

            div:first-child {
                padding-right: 10px;
                margin-right: 10px;
                border-right: 1px solid rgb(53, 63, 77);
            }

            div:last-child {
                padding-right: 0;
                margin-right: 0;
                border-right: none;
            }
        }
    }

    &-body:hover,
    &-body:active,
    &-body:focus,
    input[type='radio']:checked ~ .option-card-body,
    input[type='checkbox']:checked ~ .option-card-body {
        background-color: rgba(0, 0, 0, 0.045);
        border: 1px solid rgba(0, 0, 0, 0.065);
        box-shadow: 2px 4px 2px -2px rgba(31, 31, 31, 0.35);

        &:before {
            background: #78c044;
            -webkit-box-shadow: 0 0 0 2px #3d693d;
            box-shadow: 0 0 0 2px #3d693d;
        }
    }

    input[type='radio']:disabled ~ .option-card-body,
    input[type='radio']:disabled ~ .option-card-body:hover,
    input[type='radio']:disabled ~ .option-card-body:active,
    input[type='radio']:disabled ~ .option-card-body:focus,
    input[type='checkbox']:disabled ~ .option-card-body,
    input[type='checkbox']:disabled ~ .option-card-body:hover,
    input[type='checkbox']:disabled ~ .option-card-body:active,
    input[type='checkbox']:disabled ~ .option-card-body:focus {
        cursor: none;
        pointer-events: none;
        background-color: rgba(0, 0, 0, 0.005);
        border: 1px solid rgba(0, 0, 0, 0.045);
        box-shadow: none;
        opacity: 0.65;

        &:before {
            border: 3px solid #fff;
            -webkit-box-shadow: 0 0 0 2px #cccccc;
            box-shadow: 0 0 0 2px #cccccc;
        }
    }

    /*&-body:before {
        content: '';
        position: absolute;
        height: 12px;
        width: 12px;
        background: transparent;
        left: 15px;
        border-radius: 100%;
        border: 3px solid #fff;
        -webkit-box-shadow: 0 0 0 2px #cccccc;
        box-shadow: 0 0 0 2px #cccccc;
        transition: 150ms;
        transition-delay: 75ms;
    }
    input[type='radio']:checked ~ .option-card-body:before,
    input[type='checkbox']:checked ~ .option-card-body:before {
        background: #78c044;
        -webkit-box-shadow: 0 0 0 2px #3d693d;
        box-shadow: 0 0 0 2px #3d693d;
    }*/
}
