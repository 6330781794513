label,
input,
button,
select,
textarea {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

label {
    font-family: -apple-system, BlinkMacSystemFont, 'Inter UI', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif, sans-serif;
    letter-spacing: -0.006em;
}

select[multiple],
select[size] {
    height: auto !important;
}

input:focus,
select:focus,
textarea:focus,
input[type='file']:focus,
input[type='radio']:focus,
input[type='checkbox']:focus {
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

input:-webkit-autofill,
input.error:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #fff inset !important;
}

input:-webkit-autofill:focus,
input.error:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 1000px #fff inset !important;
}

form legend {
    margin: 15px 0 10px 0;
}

.form-control {
    background-color: #fff;
    background-image: none;
    border: 1px solid rgba(6, 18, 35, 0.14);
    border-top-color: rgba(6, 18, 35, 0.21);
    font-family: -apple-system, BlinkMacSystemFont, 'Inter UI', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif, sans-serif;
    -webkit-appearance: none;
    color: #061223;
    outline: 0;
    padding: 6px 8px;
    line-height: 16px;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    min-height: 32px;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-transition: background 0.2s linear 0s;
    transition: background 0.2s linear 0s;
}

.form-control::-moz-placeholder {
    color: inherit;
    opacity: 0.47;
    font-weight: 400;
    font-family: -apple-system, BlinkMacSystemFont, 'Inter UI', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.form-control:-ms-input-placeholder {
    color: inherit;
    opacity: 0.47;
    font-weight: 400;
    font-family: -apple-system, BlinkMacSystemFont, 'Inter UI', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.form-control::-webkit-input-placeholder {
    color: inherit;
    opacity: 0.47;
    font-weight: 400;
    font-family: -apple-system, BlinkMacSystemFont, 'Inter UI', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.form-control::placeholder {
    color: inherit;
    opacity: 0.47;
    font-weight: 400;
    font-family: -apple-system, BlinkMacSystemFont, 'Inter UI', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.form-control:focus {
    background-color: #fff;
    border: 1px solid #0c6baf;
    box-shadow: none;
    outline: 0 !important;
}

.form-control:focus::-moz-placeholder {
    color: inherit;
    opacity: 0.57;
    font-weight: 400;
    font-family: -apple-system, BlinkMacSystemFont, 'Inter UI', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.form-control:focus:-ms-input-placeholder {
    color: inherit;
    opacity: 0.57;
    font-weight: 400;
    font-family: -apple-system, BlinkMacSystemFont, 'Inter UI', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.form-control:focus::-webkit-input-placeholder {
    color: inherit;
    opacity: 0.57;
    font-weight: 400;
    font-family: -apple-system, BlinkMacSystemFont, 'Inter UI', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.form-control:focus::placeholder {
    color: inherit;
    opacity: 0.57;
    font-weight: 400;
    font-family: -apple-system, BlinkMacSystemFont, 'Inter UI', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
    background: #f9f9fa;
    color: rgba(53, 63, 77, 0.33);
    cursor: not-allowed;
}

.form-control.transparent {
    background-color: transparent;
    border-color: transparent;
}

.form-control.transparent:focus {
    background-color: transparent;
    border-color: transparent;
}

/* Input Sizes
--------------------------------------------------
*/
.input-sm,
.form-horizontal .form-group-sm .form-control {
    font-size: 13px;
    min-height: 32px;
    padding: 7px 9px;
}

.input-lg,
.form-horizontal .form-group-lg .form-control {
    border-radius: 3px;
    font-size: 18px;
    min-height: 45px;
    padding: 11px 13px;
}

.input-xlg {
    min-height: 51px;
    font-size: 18px;
    line-height: 22px;
}

.input-group-lg > .form-control {
    padding: 6px 1rem;
}

.input-group-sm > .form-control {
    padding: 0 0.5rem;
    min-height: 32px;
}

.input-group-text {
    background-color: #fff;
    color: rgba(53, 63, 77, 0.67);
    font-size: 14px;
    line-height: 14px;
    border: 1px solid rgba(6, 18, 35, 0.14);
    border-top-color: rgba(6, 18, 35, 0.21);
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 2px;
}

.input-group-text.primary {
    background-color: #0c6baf;
    color: #fff;
}

.input-group-text.success {
    background-color: #0c6baf;
    color: #fff;
}

.input-group-text.complete {
    background-color: #0c6baf;
    color: #fff;
}

.input-group-text.info {
    background-color: #47525e;
    color: #fff;
}

.input-group-text.warning {
    background-color: #fed76e;
    color: #353f4d;
}

.input-group-text.danger {
    background-color: #ea2c54;
    color: #fff;
}

.input-group-text.transparent {
    background-color: transparent;
}

.input-group-append .input-group-text {
    border-left: 0;
}

.input-group-prepend .input-group-text {
    border-right: 0;
}

/* Form layouts
--------------------------------------------------
*/
@media only screen and (min-width: 540px) {
    form .row {
        margin-left: 0;
        margin-right: 0;
    }
    form .row [class*='col-sm-']:not(:first-child),
    form .row [class*='col-sm-']:not(:last-child) {
        padding-right: 7px;
        padding-left: 7px;
    }
    form .row [class*='col-sm-']:first-child {
        padding-left: 0;
    }
    form .row [class*='col-sm-']:last-child {
        padding-right: 0;
    }
}

@media only screen and (min-width: 720px) {
    form .row {
        margin-left: 0;
        margin-right: 0;
    }
    form .row [class*='col-']:not(:first-child),
    form .row [class*='col-']:not(:last-child) {
        padding-right: 0;
        padding-left: 0;
    }
    form .row [class*='col-']:first-child {
        padding-left: 0;
    }
    form .row [class*='col-']:last-child {
        padding-right: 0;
    }
    form .row {
        margin-left: 0;
        margin-right: 0;
    }
    form .row [class*='col-md-']:not(:first-child),
    form .row [class*='col-md-']:not(:last-child) {
        padding-right: 7px;
        padding-left: 7px;
    }
    form .row [class*='col-md-']:first-child {
        padding-left: 0;
    }
    form .row [class*='col-md-']:last-child {
        padding-right: 0;
    }
}

@media only screen and (min-width: 960px) {
    form .row {
        margin-left: 0;
        margin-right: 0;
    }
    form .row [class*='col-lg-']:not(:first-child),
    form .row [class*='col-lg-']:not(:last-child) {
        padding-right: 7px;
        padding-left: 7px;
    }
    form .row [class*='col-lg-']:first-child {
        padding-left: 0;
    }
    form .row [class*='col-lg-']:last-child {
        padding-right: 0;
    }
}

@media only screen and (min-width: 1200px) {
    form .row {
        margin-left: 0;
        margin-right: 0;
    }
    form .row [class*='col-xl-']:not(:first-child),
    form .row [class*='col-xl-']:not(:last-child) {
        padding-right: 7px;
        padding-left: 7px;
    }
    form .row [class*='col-xl-']:first-child {
        padding-left: 0;
    }
    form .row [class*='col-xl-']:last-child {
        padding-right: 0;
    }
}

/* Form layouts  : Horizontal
--------------------------------------------------
*/
.form-horizontal .form-group {
    border-bottom: 1px solid #dcdee0;
    padding-top: 12px;
    padding-bottom: 12px;
    margin-bottom: 0;
}

.form-horizontal .form-group:last-child {
    border-bottom: none;
}

.form-horizontal .form-group:hover .control-label {
    opacity: 1;
    color: #061223;
}

.form-horizontal .form-group.focused .control-label {
    opacity: 1;
    color: #353f4d;
}

.form-horizontal .form-group .control-label {
    text-align: left;
    color: #353f4d;
    -webkit-transition: opacity ease 0.3s;
    transition: opacity ease 0.3s;
}

.form-horizontal .form-group .control-label.required:after {
    content: 'Required';
    position: absolute;
    right: 0;
    font-size: 12px;
    margin-right: 4px;
    color: rgba(101, 108, 119, 0.87);
}

.form-horizontal .radio {
    padding-top: 7px;
    margin-top: 0;
    margin-bottom: 0;
}

.form-horizontal .control-label {
    padding-top: 7px;
    margin-bottom: 0;
}

/* Form layouts  : Attached
--------------------------------------------------
*/
.form-group-attached .form-group.form-group-default {
    border-radius: 0;
    margin-bottom: 0;
}

.form-group-attached > div {
    margin: 0;
}

.form-group-attached > div:first-child.row > [class*='col-']:first-child .form-group-default {
    border-top-left-radius: 2px;
}

.form-group-attached > div:first-child.row > [class*='col-']:last-child .form-group-default {
    border-top-right-radius: 2px;
}

.form-group-attached > div:first-child.form-group-default {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
}

.form-group-attached > div:last-child.row > [class*='col-']:first-child .form-group-default {
    border-bottom-left-radius: 2px;
}

.form-group-attached > div:last-child.row > [class*='col-']:last-child .form-group-default {
    border-bottom-right-radius: 2px;
}

.form-group-attached > div:last-child.form-group-default {
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
}

.form-group-attached > div.row > [class*='col-'] {
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.form-group-attached > div.row > [class*='col-']:not(:only-child):not(:last-child) > .form-group-default {
    border-right-color: transparent;
}

.form-group-attached > div:not(:last-child) .form-group-default,
.form-group-attached > div:not(:last-child).form-group-default {
    border-bottom-color: transparent;
}

/* Form layouts  : Responsive Handlers
--------------------------------------------------
*/
@media (max-width: 767px) {
    .form-group-attached .form-group-default {
        border-right-color: rgba(6, 18, 35, 0.14) !important;
    }
}

@media only screen and (min-width: 768px) {
    .form-group-attached > div.row > [class*='col-'] .form-group {
        height: 100%;
        width: 100%;
    }
}

/* Form Groups
--------------------------------------------------
*/
.form-group {
    margin-bottom: 5px !important;
}

.form-group label:not(.error) {
    color: #353f4d;
    transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1), color 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.form-group label .help {
    margin-left: 8px;
}

.form-group .help {
    font-size: 12px;
    color: rgba(6, 18, 35, 0.67);
    letter-spacing: normal;
    line-height: 18px;
    display: block;
    margin-top: 6px;
    margin-left: 3px;
}

@keyframes shake {
    8%,
    41% {
        -webkit-transform: translateX(-4px);
    }
    25%,
    58% {
        -webkit-transform: translateX(4px);
    }
    75% {
        -webkit-transform: translateX(-2px);
    }
    92% {
        -webkit-transform: translateX(2px);
    }
    0%,
    100% {
        -webkit-transform: translateX(0);
    }
}

.form-group-default {
    background-color: #fff;
    font-family: -apple-system, BlinkMacSystemFont, 'Inter UI', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    position: relative;
    border: 1px solid rgba(6, 18, 35, 0.14);
    border-top-color: rgba(0, 0, 0, 0.14);
    border-radius: 2px;
    padding: 5px 9px 4px;
    overflow: hidden;
    width: 100%;
    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease;
    cursor: text;
}

.form-group-default.form-check-group {
    cursor: default;
}

.form-group-default.form-check-group label {
    cursor: pointer;
}

.form-group-default.required:after {
    color: #ea2c54;
    content: '*';
    font-family: -apple-system, BlinkMacSystemFont, 'Inter UI', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 20px;
    position: absolute;
    right: 10px;
    top: 2px;
}

.form-group-default.disabled {
    background: #f9f9fa;
    color: rgba(53, 63, 77, 0.23);
    cursor: not-allowed;
}

.form-group-default.disabled.focused {
    border: 1px solid #0c6baf;
}

.form-group-default.disabled.focused label {
    opacity: 1;
}

.form-group-default.disabled label,
.form-group-default-react-select.disable label {
    opacity: 0.57;
    cursor: not-allowed;
}

.form-group-default.focused,
.form-group-default-react-select.focused {
    border: 1px solid #0c6baf !important;
}

.form-group-default.focused label,
.form-group-default-react-select.focused label {
    opacity: 0.67;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
    font-size: 12px;
    letter-spacing: normal;
}

.form-group-default.toggle-required,
.form-group-react-select.toggle-required label,
.form-group-default-react-select.toggle-required label .form-group-default.toggle-required label {
    animation-name: shake;
    animation-duration: 0.4s;
    animation-iteration-count: 1;
}

.form-group-default.has-error label {
    color: #c81a32;
}

.form-group-default.has-success .form-control-feedback,
.form-group-default.has-error .form-control-feedback {
    display: none !important;
}

.form-group-default.has-success .form-control,
.form-group-default.has-success .form-control:focus,
.form-group-default.has-error .form-control,
.form-group-default.has-error .form-control:focus {
    border: none;
    box-shadow: none;
}

.form-group-default + .error {
    margin-top: -5px;
    padding: 5px 7px 2px 4px;
    display: flex;
    letter-spacing: normal;
    animation-name: show-msg;
    animation-duration: 450ms;
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    position: relative;
}

.form-group-default + .error:after {
    content: '';
    width: 0;
    position: absolute;
    height: 1px;
    background-color: #ea2c54;
    /* top: 0; */
    right: 0;
    animation-name: show-line;
    animation-delay: 300ms;
    animation-duration: 350ms;
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    animation-fill-mode: forwards;
}

@keyframes show-msg {
    0% {
        height: 0;
        opacity: 0;
    }
    100% {
        height: 25px;
        opacity: 1;
    }
}

@keyframes show-line {
    0% {
        width: 0;
    }
    100% {
        width: 100%;
    }
}

.form-group-default.input-group {
    padding: 0;
}

.form-group-default.input-group > label {
    margin-top: 6px;
    padding-left: 12px;
}

.form-group-default.input-group > label.inline {
    margin-top: 6px;
    float: left;
}

.form-group-default.input-group > .form-control {
    margin-top: -2px;
    margin-bottom: 3px;
    padding-left: 12px;
}

.form-group-default.input-group label {
    margin-top: 6px;
    padding-left: 12px;
}

.form-group-default.input-group .form-input-group {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
}

.form-group-default.input-group .form-control {
    margin-top: -2px;
    margin-bottom: 3px;
    padding-left: 12px;
    width: 100%;
}

.form-group-default.input-group .input-group-text {
    border: 0;
    border-radius: 0;
    min-width: 50px;
}

.form-group-default.input-group .input-group-text i {
    width: 100%;
}

.form-group-default.input-group .btn {
    border-top: 0;
    border-bottom: 0;
}

.form-group-default .form-control {
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.form-group-default .form-control.error {
    color: #061223;
}

.form-group-default .form-control:focus {
    background: none;
}

.form-group-default .form-control:focus::-moz-placeholder {
    color: inherit;
    opacity: 0.77;
    font-weight: 400;
    font-family: -apple-system, BlinkMacSystemFont, 'Inter UI', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.form-group-default .form-control:focus:-ms-input-placeholder {
    color: inherit;
    opacity: 0.77;
    font-weight: 400;
    font-family: -apple-system, BlinkMacSystemFont, 'Inter UI', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.form-group-default .form-control:focus::-webkit-input-placeholder {
    color: inherit;
    opacity: 0.77;
    font-weight: 400;
    font-family: -apple-system, BlinkMacSystemFont, 'Inter UI', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.form-group-default .form-control:focus::placeholder {
    color: inherit;
    opacity: 0.77;
    font-weight: 400;
    font-family: -apple-system, BlinkMacSystemFont, 'Inter UI', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.form-group-default .form-control:-webkit-autofill,
.form-group-default .form-control.error:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #fff inset !important;
}

.form-group-default .form-control:-webkit-autofill:focus,
.form-group-default .form-control.error:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 1000px #fff inset !important;
}

.form-group-default textarea.form-control {
    padding-top: 5px;
}

.form-group-default label {
    margin: 0;
    display: block;
    opacity: 1;
    cursor: text;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
}

.form-group-default label.label-lg {
    font-size: 13px;
    left: 13px;
    top: 9px;
}

.form-group-default label.label-sm {
    font-size: 11px;
    left: 11px;
    top: 6px;
}

.form-group-default label.highlight {
    opacity: 1;
}

.form-group-default label.fade {
    opacity: 0.5;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
    font-size: 12px;
    letter-spacing: normal;
}

.form-group-default > .input-lg {
    height: 29px;
    min-height: 29px;
    padding-left: 1px;
}

.form-group-default > .input-sm {
    min-height: 18px;
    height: 18px;
}

.form-group-default.form-group-default-select {
    overflow: visible;
}

.form-group-default.form-group-default-select .ui-select-container.ui-select-bootstrap.dropdown .ui-select-match {
    padding-top: 6px;
    height: 23px;
}

.form-group-default.form-group-default-select .ui-select-container .select2-choices {
    border: 0;
}

.form-group-default.form-group-default-select2 {
    padding: 0;
}

.form-group-default.form-group-default-select2 > label {
    position: absolute;
    z-index: 10;
    padding: 5px 12px 0 9px;
    color: #656c77;
}

.form-group-default.form-group-default-select2 > label.label-lg {
    left: 0;
    top: 0;
}

.form-group-default.form-group-default-select2 > label.label-sm {
    left: 0;
    top: 0;
}

.form-group-default.form-group-default-select2 .select2-container .select2-selection--single {
    padding-top: 20px;
    height: 52px;
}

.form-group-default.form-group-default-select2 .select2-container .select2-selection--single .select2-arrow b:before {
    top: 20px;
}

.form-group-default.form-group-default-select2 .select2-container .select2-selection--single .select2-chosen {
    padding-left: 3px;
    padding-top: 1px;
}

.form-group-default.form-group-default-select2 .select2-container .select2-selection--multiple {
    padding-top: 19px;
    padding-bottom: 0;
    min-height: 52px;
    border: 0 !important;
}

.form-group-default.form-group-default-select2
    .select2-container
    .select2-selection--multiple
    .select2-selection__rendered
    .select2-selection__choice {
    margin-top: 5px;
}

.form-group-default.form-group-default-select2.input-lg {
    height: auto;
    padding: 0;
}

.form-group-default.form-group-default-select2.input-lg .select2-container .select2-selection--single {
    padding-top: 26px;
    height: 58px;
}

.form-group-default.form-group-default-select2.input-lg
    .select2-container
    .select2-selection--single
    .select2-selection__rendered {
    font-size: 18px;
}

.form-group-default.form-group-default-select2.input-sm {
    height: auto;
    padding: 0;
}

.form-group-default.form-group-default-select2.input-sm .select2-container .select2-selection--single {
    padding-top: 21px;
    height: 49px;
}

.form-group-default.form-group-default-select2.input-sm
    .select2-container
    .select2-selection--single
    .select2-selection__rendered {
    font-size: 13px;
}

.form-group-default.form-group-default-selectFx {
    padding: 0;
}

.form-group-default.form-group-default-selectFx > label {
    position: absolute;
    z-index: 10;
    padding: 7px 12px 0 12px;
}

.form-group-default.form-group-default-selectFx > label.label-lg {
    left: 0;
    top: 0;
}

.form-group-default.form-group-default-selectFx > label.label-sm {
    left: 0;
    top: 0;
}

.form-group-default.form-group-default-selectFx .cs-wrapper .cs-placeholder {
    padding-top: 28px;
    height: 52px;
    padding-left: 12px;
}

.form-group-default.form-group-default-selectFx .cs-wrapper .cs-select {
    height: auto;
}

.form-group-default.form-group-default-selectFx .cs-wrapper .cs-select > span:after,
.form-group-default.form-group-default-selectFx .cs-wrapper .cs-select .cs-selected span:after {
    top: 39px;
}

.form-group-default.form-group-default-selectFx .cs-wrapper .cs-select.input-lg .cs-placeholder {
    height: 60px;
}

.form-group-default.form-group-default-selectFx .cs-wrapper .cs-select.input-sm .cs-placeholder {
    height: 50px;
}

.form-group-default.form-group-default-selectFx .cs-wrapper .dropdown-placeholder {
    vertical-align: top;
}

.form-group-default .input-group-append .input-group-text:before {
    content: '';
    width: 1px;
    height: 80%;
    top: 0;
    left: -10px;
    position: relative;
    background-color: rgba(0, 0, 0, 0.16);
}

.form-group-default .input-group-prepend .input-group-text:before {
    content: '';
    width: 1px;
    height: 80%;
    top: 0;
    right: -10px;
    position: relative;
    background-color: rgba(0, 0, 0, 0.16);
}

/*  Activation & Reset code inputs
-------------------------------------------------*/
input[name*='activationCode'],
input[name*='resetCode'] {
    font-weight: 400;
    text-align: center;
    line-height: 22px;
    border-radius: 6px;
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    min-height: 48px;
    min-width: 48px;
}

input.has-error[name*='activationCode'],
input.has-error[name*='resetCode'] {
    border-color: #ea2c54;
    -webkit-box-shadow: none;
    box-shadow: none;
}

input.has-error[name*='activationCode']:focus,
input.has-error[name*='resetCode']:focus {
    border-color: #ce153c;
    -webkit-box-shadow: none;
    box-shadow: none;
}

/* Form validation
--------------------------------------------------
*/
.has-success .help-block,
.has-success .control-label,
.has-success .radio,
.has-success .checkbox,
.has-success .radio-inline,
.has-success .checkbox-inline {
    color: #0057bc;
}

.has-success .form-control {
    border-color: #0057bc;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.has-success .form-control:focus {
    border-color: #004089;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.has-success .form-control:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #fff inset, 0 0 0 1px rgba(0, 0, 0, 0.1), 0 -1px 0 0 rgba(0, 0, 0, 0.05) !important;
}

.has-success .input-group-addon {
    background: #f3f3f4;
    border: 1px solid rgba(6, 18, 35, 0.14);
    color: rgba(53, 63, 77, 0.47);
}

.has-success .form-control-feedback {
    color: #0057bc;
}

.has-warning .help-block,
.has-warning .control-label,
.has-warning .radio,
.has-warning .checkbox,
.has-warning .radio-inline,
.has-warning .checkbox-inline {
    color: #d8a94f;
}

.has-warning .form-control {
    border-color: #d8a94f;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.has-warning .form-control:focus {
    border-color: #c7932d;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.has-warning .form-control:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #fff inset, 0 0 0 1px rgba(0, 0, 0, 0.1), 0 -1px 0 0 rgba(0, 0, 0, 0.05) !important;
}

.has-warning .input-group-addon {
    background: #f3f3f4;
    border: 1px solid rgba(6, 18, 35, 0.14);
    color: rgba(53, 63, 77, 0.47);
}

.has-warning .form-control-feedback {
    color: #d8a94f;
}

.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline {
    color: #ea2c54;
}

.has-error .form-control {
    border-color: #ea2c54;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.has-error .form-control:focus {
    border-color: #ce153c;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.has-error .form-control:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #fff inset, 0 0 0 1px rgba(0, 0, 0, 0.1), 0 -1px 0 0 rgba(0, 0, 0, 0.05) !important;
}

.has-error .input-group-addon {
    background: #f3f3f4;
    border: 1px solid rgba(6, 18, 35, 0.14);
    color: rgba(53, 63, 77, 0.47);
}

.has-error .form-control-feedback {
    color: #ea2c54;
}

label.error {
    font-size: 12px;
    color: #c81a32;
    display: block;
}

.validation-popover {
    background: #000;
    box-shadow: rgba(0, 0, 0, 0.3) 0 1px 4px;
}

.validation-popover .popover-body {
    padding: 4px 8px;
    font-size: 12px;
    line-height: 1.4;
    color: #fff;
}

.validation-popover > .arrow::after {
    border-top-color: #000;
}

/*------------------------------------------------------------------
Checkbox
*/
.form-check {
    margin-bottom: 10px;
    padding-left: 0;
}

.form-check label {
    display: inline-block;
    cursor: pointer;
    position: relative;
    padding-left: 25px;
    min-width: 18px;
    min-height: 18px;
    margin-bottom: 0;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

.form-check label:before {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    left: 0;
    -webkit-box-sizing: inherit;
    box-sizing: border-box;
    background-color: #fff;
    border: 1px solid #c2c5ca;
}

.form-check label {
    transition: border 140ms linear 0s, color 140ms linear 0s, background-color 140ms linear 0s;
}

.form-check label:hover {
    color: #061223;
}

.form-check label:before {
    top: 2px;
    border-radius: 3px;
    transition: border 140ms linear 0s, color 140ms linear 0s, background-color 140ms linear 0s;
}

.form-check input[type='checkbox'] {
    position: absolute;
    margin: 0;
    top: 3px;
    z-index: -1;
    width: 16px;
    height: 16px;
    opacity: 0;
}

.form-check input[type='checkbox'] + label::after {
    content: '';
    position: absolute;
    top: 2px;
    left: 0;
    border-right: 0 solid transparent;
    border-bottom: 0 solid transparent;
    width: 16px;
    height: 16px;
    overflow: hidden;
}

.form-check.checkbox-circle label:after {
    border-radius: 99px;
}

.form-check.checkbox-circle label:before {
    border-radius: 99px;
}

.form-check input[type='checkbox']:checked + label::after {
    content: '';
    background-size: 160px 16px;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAAAQAgMAAADsa5zLAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAMUExURUdwTHBwcG9vb3BwcBFjhIYAAAAEdFJOUwBG9tQE3MceAAAAVUlEQVQoz2NgGLKA0QGIDwDxBSCeAMEYgAWI2YCK2CagYgwgDcRSDhgYbAkKzsSKGdgakCyY6ADES7BiiCkgJ4PYyybgxAhQAsRZDrgxCpDEg4cAAAAp2ibhZRGLHgAAAABJRU5ErkJggg==')
        no-repeat left center;
    animation-name: checkbox-check;
    animation-duration: 320ms;
    animation-timing-function: steps(9);
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
}

.form-check input[type='checkbox']:hover:active:not(:checked) + label:before {
    background-color: rgba(0, 0, 0, 0.08);
}

.form-check input[type='checkbox']:focus + label {
    color: #061223;
}

.form-check input[type='checkbox']:focus + label:before {
    outline: none !important;
    box-shadow: 0 0 0 2px #78c8fe;
}

.form-check input[type='checkbox'][disabled] + label {
    cursor: not-allowed !important;
    color: #353f4d;
    opacity: 0.58;
}

.form-check input[type='checkbox'][disabled] + label:before {
    cursor: not-allowed !important;
    background: #eaeaec;
}

.form-check input[type='checkbox']:indeterminate + label:after {
    background: #353f4d none;
    width: 10px;
    height: 2px;
    top: 6px;
    margin: 3px;
    border-radius: 2px;
}

.form-check.right label {
    padding-left: 0;
    padding-right: 26px;
}

.form-check.right label:before {
    right: 0;
    left: auto;
}

.form-check.right input[type='checkbox']:checked + label {
    position: relative;
}

.form-check.right input[type='checkbox']:checked + label::after {
    position: absolute;
    right: 0;
    left: auto;
}

.success input[type='checkbox']:checked + label:before,
.success input[type='checkbox']:indeterminate + label:before {
    border-color: #0c6baf;
    background-color: #0c6baf;
}

.primary input[type='checkbox']:checked + label:before,
.primary input[type='checkbox']:indeterminate + label:before {
    border-color: #0c6baf;
    background-color: #0c6baf;
}

.complete input[type='checkbox']:checked + label:before,
.complete input[type='checkbox']:indeterminate + label:before {
    border-color: #0c6baf;
    background-color: #0c6baf;
}

.warning input[type='checkbox']:checked + label:before,
.warning input[type='checkbox']:indeterminate + label:before {
    border-color: #fed76e;
    background-color: #fed76e;
}

.danger input[type='checkbox']:checked + label:before,
.danger input[type='checkbox']:indeterminate + label:before {
    border-color: #ea2c54;
    background-color: #ea2c54;
}

.info input[type='checkbox']:checked + label:before,
.info input[type='checkbox']:indeterminate + label:before {
    border-color: #47525e;
    background-color: #47525e;
}

.info input[type='checkbox']:checked + label::after,
.danger input[type='checkbox']:checked + label::after,
.complete input[type='checkbox']:checked + label::after,
.primary input[type='checkbox']:checked + label::after,
.success input[type='checkbox']:checked + label::after {
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAAAQAgMAAADsa5zLAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAMUExURUdwTP///////////waf0AoAAAAEdFJOUwBG9tQE3MceAAAAVUlEQVQoz2NgGLKA0QGIDwDxBSCeAMEYgAWI2YCK2CagYgwgDcRSDhgYbAkKzsSKGdgakCyY6ADES7BiiCkgJ4PYyybgxAhQAsRZDrgxCpDEg4cAAAAp2ibhZRGLHgAAAABJRU5ErkJggg==')
        left center;
}

.info input[type='checkbox']:indeterminate + label::after,
.danger input[type='checkbox']:indeterminate + label::after,
.complete input[type='checkbox']:indeterminate + label::after,
.primary input[type='checkbox']:indeterminate + label::after,
.success input[type='checkbox']:indeterminate + label::after {
    background-color: #fff;
}

@keyframes shrink-bounce {
    0% {
        transform: scale(1);
    }
    33% {
        transform: scale(0.85);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes checkbox-check {
    0% {
        background-position: 0;
    }
    100% {
        background-position: -144px;
    }
}

/* hide focus style if not from keyboard navigation */
.js-focus-visible .form-check input[type='checkbox']:focus:not(.focus-visible) + label:before {
    box-shadow: none;
}

.form-check input[type='radio'] + label:before {
    bottom: 2.5px;
    border-radius: 99px;
    -webkit-transition: border 0.3s 0s cubic-bezier(0.455, 0.03, 0.215, 1.33);
    transition: border 0.3s 0s cubic-bezier(0.455, 0.03, 0.215, 1.33);
}

.form-check input[type='radio']:checked + label:before {
    border-color: #656c77;
    border-width: 5px;
}

.form-check input[type='radio']:hover:active:not(:checked) + label:before {
    background-color: rgba(0, 0, 0, 0.08);
}

.form-check input[type='radio']:focus + label {
    color: #061223;
}

.form-check input[type='radio']:focus + label:before {
    outline: none !important;
    box-shadow: 0 0 0 2px #78c8fe;
}

.form-check input[type='radio'] {
    opacity: 0;
    position: absolute;
    top: 3px;
    width: 16px;
    height: 16px;
}

.form-check input[type='radio'][disabled] + label {
    cursor: not-allowed !important;
    color: #353f4d;
    opacity: 0.58;
}

.form-check input[type='radio'][disabled] + label:before {
    cursor: not-allowed !important;
    background: #eaeaec;
}

.success input[type='radio']:checked + label:before {
    border-color: #0c6baf;
}

.primary input[type='radio']:checked + label:before {
    border-color: #0c6baf;
}

.info input[type='radio']:checked + label:before {
    border-color: #47525e;
}

.warning input[type='radio']:checked + label:before {
    border-color: #fed76e;
}

.danger input[type='radio']:checked + label:before {
    border-color: #ea2c54;
}

.complete input[type='radio']:checked + label:before {
    border-color: #0c6baf;
}

/* hide focus style if not from keyboard navigation */
.js-focus-visible .form-check input[type='radio']:focus:not(.focus-visible) + label:before {
    box-shadow: none;
}

/* Scale */
.form-check.check-lg {
    padding: 0;
    margin: 10px 0;
    z-index: 1;
}
.form-check.check-lg.checkbox-circle label {
    height: 56px;
    width: 100%;
    border: 1px solid rgba(6, 18, 35, 0.14);
    border-top-color: rgba(0, 0, 0, 0.14);
    border-radius: 2px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    padding-left: 75px;
    cursor: pointer;
}
.form-check.check-lg.checkbox-circle label:after,
.form-check.check-lg.checkbox-circle label:before {
    top: 19px;
    left: 14px;
    transform: scale(1.75);
}

.form-check.check-lg.checkbox-circle input[type='checkbox']:checked + label::after {
    transform: scale(1.2);
}

.form-check.check-lg.checkbox-circle input[type='checkbox']:checked + label {
    background-color: #0c6baf;
    border-color: #0c6baf;
    color: #ffffff;
}

/*------------------------------------------------------------------
Switch
*/
.form-check.switch input[type='checkbox'] + label {
    padding-left: 34px;
}

.form-check.switch input[type='checkbox'] + label:before {
    cursor: pointer;
    width: 26px;
    height: 18px;
    top: 2px;
    background-image: -webkit-linear-gradient(
        to right,
        #0c6baf 0%,
        #0f98f5 44%,
        rgba(0, 0, 0, 0.14) 62%,
        rgba(0, 0, 0, 0.04) 97%
    );
    background-image: -moz-linear-gradient(
        to right,
        #0c6baf 0%,
        #0f98f5 44%,
        rgba(0, 0, 0, 0.14) 62%,
        rgba(0, 0, 0, 0.04) 97%
    );
    background-image: linear-gradient(
        to right,
        #0c6baf 0%,
        #0f98f5 44%,
        rgba(0, 0, 0, 0.14) 62%,
        rgba(0, 0, 0, 0.04) 97%
    );
    background-size: 300%;
    background-position: right;
    border-radius: 50px;
    border: none;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.12);
    transition: background 310ms, box-shadow 250ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.form-check.switch input[type='checkbox'] + label:after {
    transform: translateX(0%);
    background: #fff;
    top: 4px;
    left: 2px;
    width: 14px;
    height: 14px;
    border-radius: 9px;
    box-shadow: 1px 0 1px 0.5px rgba(0, 0, 0, 0.12), 2px 4px 6px rgba(0, 0, 0, 0.2);
    transition: transform, box-shadow;
    transition-duration: 250ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.form-check.switch input[type='checkbox']:checked + label:before {
    background-position: left;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.12);
}

.form-check.switch input[type='checkbox']:checked + label:after {
    transform: translateX(calc(100% - 6px));
    box-shadow: 1px 1 px0 rgba(0, 0, 0, 0.08), -3px 3px 6px rgba(0, 0, 0, 0.3);
}

.form-check.switch input[type='checkbox']:focus + label:before {
    outline: none !important;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.12), 0 0 0 2px #78c8fe;
}

.form-check.switch input[type='checkbox'][disabled]:active + label:after {
    transform: scaleX(1.1);
    transform-origin: center left;
    transition: transform step-start;
}

.form-check.switch input[type='checkbox']:checked[disabled]:active + label:after {
    transform: translateX(calc(100% - 6px)) scaleX(1.1);
    transform-origin: center right;
}

.form-check.switch input[type='checkbox']:hover:active + label:before {
    background-color: transparent;
}

.form-check.switch.right input[type='checkbox'] + label {
    padding-left: 0;
    padding-right: 36px;
}

.form-check.switch.right input[type='checkbox'] + label:before {
    right: 0;
    left: auto;
}

.form-check.switch.right input[type='checkbox'] + label:after {
    right: 10px;
    left: auto;
}

.form-check.switch.switch-lg input[type='checkbox'] + label {
    padding-left: 42px;
}

.form-check.switch.switch-lg input[type='checkbox'] + label:before {
    width: 34px;
    height: 22px;
    top: 0;
}

.form-check.switch.switch-lg input[type='checkbox'] + label:after {
    width: 18px;
    height: 18px;
    top: 2px;
}

.form-check.switch.switch-lg.right input[type='checkbox'] + label {
    padding-left: 0;
    padding-right: 44px;
}

.form-check.switch.switch-lg.right input[type='checkbox'] + label:after {
    right: 14px;
}

.form-check.switch.success input[type='checkbox'] + label:before {
    background-image: linear-gradient(
        to right,
        #0c6baf 0%,
        #0f98f5 44%,
        rgba(0, 0, 0, 0.14) 62%,
        rgba(0, 0, 0, 0.04) 97%
    );
}

.form-check.switch.complete input[type='checkbox'] + label:before {
    background-image: linear-gradient(
        to right,
        #0c6baf 0%,
        #0f98f5 44%,
        rgba(0, 0, 0, 0.14) 62%,
        rgba(0, 0, 0, 0.04) 97%
    );
}

.form-check.switch.warning input[type='checkbox'] + label:before {
    background-image: linear-gradient(
        to right,
        #fed76e 0%,
        #ffe47c 44%,
        rgba(0, 0, 0, 0.14) 62%,
        rgba(0, 0, 0, 0.04) 97%
    );
}

.form-check.switch.danger input[type='checkbox'] + label:before {
    background-image: linear-gradient(
        to right,
        #ea2c54 0%,
        #f5386c 44%,
        rgba(0, 0, 0, 0.14) 62%,
        rgba(0, 0, 0, 0.04) 97%
    );
}

.form-check.switch.info input[type='checkbox'] + label:before {
    background-image: linear-gradient(
        to right,
        #47525e 0%,
        #506577 44%,
        rgba(0, 0, 0, 0.14) 62%,
        rgba(0, 0, 0, 0.04) 97%
    );
}

/* hide focus style if not from keyboard navigation */
.js-focus-visible .form-check.switch input[type='checkbox']:focus:not(.focus-visible) + label:before {
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.12);
}

/* Accessibility focus for screen readers*/
.form-check.switch input[type='checkbox'] {
    top: 1px;
    width: 28px;
    height: 18px;
}

.form-check.switch.switch-lg input[type='checkbox'] {
    top: 0;
    width: 34px;
    height: 22px;
}

.form-check input[type='checkbox']:focus + label:before {
    outline: none !important;
    box-shadow: none !important;
}

/*  Form Elements
-------------------------------------*/
label,
input,
button,
select,
textarea {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

label {
    font-family: -apple-system, BlinkMacSystemFont, 'Inter UI', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif, sans-serif;
    letter-spacing: -0.006em;
}

select[multiple],
select[size] {
    height: auto !important;
}

input:focus,
select:focus,
textarea:focus,
input[type='file']:focus,
input[type='radio']:focus,
input[type='checkbox']:focus {
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

input:-webkit-autofill,
input.error:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #fff inset !important;
}

input:-webkit-autofill:focus,
input.error:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 1000px #fff inset !important;
}

form legend {
    margin: 15px 0 10px 0;
}

.form-control {
    background-color: #fff;
    background-image: none;
    border: 1px solid rgba(6, 18, 35, 0.14);
    border-top-color: rgba(6, 18, 35, 0.21);
    font-family: -apple-system, BlinkMacSystemFont, 'Inter UI', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif, sans-serif;
    -webkit-appearance: none;
    color: #061223;
    outline: 0;
    padding: 6px 8px;
    line-height: 16px;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    min-height: 32px;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-transition: background 0.2s linear 0s;
    transition: background 0.2s linear 0s;
}

.form-control::-moz-placeholder {
    color: inherit;
    opacity: 0.47;
    font-weight: 400;
    font-family: -apple-system, BlinkMacSystemFont, 'Inter UI', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.form-control:-ms-input-placeholder {
    color: inherit;
    opacity: 0.47;
    font-weight: 400;
    font-family: -apple-system, BlinkMacSystemFont, 'Inter UI', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.form-control::-webkit-input-placeholder {
    color: inherit;
    opacity: 0.47;
    font-weight: 400;
    font-family: -apple-system, BlinkMacSystemFont, 'Inter UI', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.form-control::placeholder {
    color: inherit;
    opacity: 0.47;
    font-weight: 400;
    font-family: -apple-system, BlinkMacSystemFont, 'Inter UI', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.form-control:focus {
    background-color: #fff;
    border: 1px solid #0c6baf;
    box-shadow: none;
    outline: 0 !important;
}

.form-control:focus::-moz-placeholder {
    color: inherit;
    opacity: 0.57;
    font-weight: 400;
    font-family: -apple-system, BlinkMacSystemFont, 'Inter UI', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.form-control:focus:-ms-input-placeholder {
    color: inherit;
    opacity: 0.57;
    font-weight: 400;
    font-family: -apple-system, BlinkMacSystemFont, 'Inter UI', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.form-control:focus::-webkit-input-placeholder {
    color: inherit;
    opacity: 0.57;
    font-weight: 400;
    font-family: -apple-system, BlinkMacSystemFont, 'Inter UI', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.form-control:focus::placeholder {
    color: inherit;
    opacity: 0.57;
    font-weight: 400;
    font-family: -apple-system, BlinkMacSystemFont, 'Inter UI', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
    background: #f9f9fa;
    color: rgba(53, 63, 77, 0.33);
    cursor: not-allowed;
}

.form-control.transparent {
    background-color: transparent;
    border-color: transparent;
}

.form-control.transparent:focus {
    background-color: transparent;
    border-color: transparent;
}

/* Input Sizes
--------------------------------------------------
*/
.input-sm,
.form-horizontal .form-group-sm .form-control {
    font-size: 13px;
    min-height: 32px;
    padding: 7px 9px;
}

.input-lg,
.form-horizontal .form-group-lg .form-control {
    border-radius: 3px;
    font-size: 18px;
    min-height: 45px;
    padding: 11px 13px;
}

.input-xlg {
    min-height: 51px;
    font-size: 18px;
    line-height: 22px;
}

.input-group-lg > .form-control {
    padding: 6px 1rem;
}

.input-group-sm > .form-control {
    padding: 0 0.5rem;
    min-height: 32px;
}

.input-group-text {
    background-color: #fff;
    color: rgba(53, 63, 77, 0.67);
    font-size: 14px;
    line-height: 14px;
    border: 1px solid rgba(6, 18, 35, 0.14);
    border-top-color: rgba(6, 18, 35, 0.21);
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 2px;
}

.input-group-text.primary {
    background-color: #0c6baf;
    color: #fff;
}

.input-group-text.success {
    background-color: #0c6baf;
    color: #fff;
}

.input-group-text.complete {
    background-color: #0c6baf;
    color: #fff;
}

.input-group-text.info {
    background-color: #47525e;
    color: #fff;
}

.input-group-text.warning {
    background-color: #fed76e;
    color: #353f4d;
}

.input-group-text.danger {
    background-color: #ea2c54;
    color: #fff;
}

.input-group-text.transparent {
    background-color: transparent;
}

.input-group-append .input-group-text {
    border-left: 0;
}

.input-group-prepend .input-group-text {
    border-right: 0;
}

/* Form layouts
--------------------------------------------------
*/
@media only screen and (min-width: 540px) {
    form .row {
        margin-left: 0;
        margin-right: 0;
    }
    form .row [class*='col-sm-']:not(:first-child),
    form .row [class*='col-sm-']:not(:last-child) {
        padding-right: 7px;
        padding-left: 7px;
    }
    form .row [class*='col-sm-']:first-child {
        padding-left: 0;
    }
    form .row [class*='col-sm-']:last-child {
        padding-right: 0;
    }
}

@media only screen and (min-width: 720px) {
    form .row {
        margin-left: 0;
        margin-right: 0;
    }
    form .row [class*='col-']:not(:first-child),
    form .row [class*='col-']:not(:last-child) {
        padding-right: 0;
        padding-left: 0;
    }
    form .row [class*='col-']:first-child {
        padding-left: 0;
    }
    form .row [class*='col-']:last-child {
        padding-right: 0;
    }
    form .row {
        margin-left: 0;
        margin-right: 0;
    }
    form .row [class*='col-md-']:not(:first-child),
    form .row [class*='col-md-']:not(:last-child) {
        padding-right: 7px;
        padding-left: 7px;
    }
    form .row [class*='col-md-']:first-child {
        padding-left: 0;
    }
    form .row [class*='col-md-']:last-child {
        padding-right: 0;
    }
}

@media only screen and (min-width: 960px) {
    form .row {
        margin-left: 0;
        margin-right: 0;
    }
    form .row [class*='col-lg-']:not(:first-child),
    form .row [class*='col-lg-']:not(:last-child) {
        padding-right: 7px;
        padding-left: 7px;
    }
    form .row [class*='col-lg-']:first-child {
        padding-left: 0;
    }
    form .row [class*='col-lg-']:last-child {
        padding-right: 0;
    }
}

@media only screen and (min-width: 1200px) {
    form .row {
        margin-left: 0;
        margin-right: 0;
    }
    form .row [class*='col-xl-']:not(:first-child),
    form .row [class*='col-xl-']:not(:last-child) {
        padding-right: 7px;
        padding-left: 7px;
    }
    form .row [class*='col-xl-']:first-child {
        padding-left: 0;
    }
    form .row [class*='col-xl-']:last-child {
        padding-right: 0;
    }
}

/* Form layouts  : Horizontal
--------------------------------------------------
*/
.form-horizontal .form-group {
    border-bottom: 1px solid #dcdee0;
    padding-top: 12px;
    padding-bottom: 12px;
    margin-bottom: 0;
}

.form-horizontal .form-group:last-child {
    border-bottom: none;
}

.form-horizontal .form-group:hover .control-label {
    opacity: 1;
    color: #061223;
}

.form-horizontal .form-group.focused .control-label {
    opacity: 1;
    color: #353f4d;
}

.form-horizontal .form-group .control-label {
    text-align: left;
    color: #353f4d;
    -webkit-transition: opacity ease 0.3s;
    transition: opacity ease 0.3s;
}

.form-horizontal .form-group .control-label.required:after {
    content: 'Required';
    position: absolute;
    right: 0;
    font-size: 12px;
    margin-right: 4px;
    color: rgba(101, 108, 119, 0.87);
}

.form-horizontal .radio {
    padding-top: 7px;
    margin-top: 0;
    margin-bottom: 0;
}

.form-horizontal .control-label {
    padding-top: 7px;
    margin-bottom: 0;
}

/* Form layouts  : Attached
--------------------------------------------------
*/
.form-group-attached .form-group.form-group-default {
    border-radius: 0;
    margin-bottom: 0;
}

.form-group-attached > div {
    margin: 0;
}

.form-group-attached > div:first-child.row > [class*='col-']:first-child .form-group-default {
    border-top-left-radius: 2px;
}

.form-group-attached > div:first-child.row > [class*='col-']:last-child .form-group-default {
    border-top-right-radius: 2px;
}

.form-group-attached > div:first-child.form-group-default {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
}

.form-group-attached > div:last-child.row > [class*='col-']:first-child .form-group-default {
    border-bottom-left-radius: 2px;
}

.form-group-attached > div:last-child.row > [class*='col-']:last-child .form-group-default {
    border-bottom-right-radius: 2px;
}

.form-group-attached > div:last-child.form-group-default {
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
}

.form-group-attached > div.row > [class*='col-'] {
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.form-group-attached > div.row > [class*='col-']:not(:only-child):not(:last-child) > .form-group-default {
    border-right-color: transparent;
}

.form-group-attached > div:not(:last-child) .form-group-default,
.form-group-attached > div:not(:last-child).form-group-default {
    border-bottom-color: transparent;
}

/* Form layouts  : Responsive Handlers
--------------------------------------------------
*/
@media (max-width: 767px) {
    .form-group-attached .form-group-default {
        border-right-color: rgba(6, 18, 35, 0.14) !important;
    }
}

@media only screen and (min-width: 768px) {
    .form-group-attached > div.row > [class*='col-'] .form-group {
        height: 100%;
        width: 100%;
    }
}

/* Form Groups
--------------------------------------------------
*/
.form-group {
    margin-bottom: 10px;
}

.form-group label:not(.error) {
    color: #353f4d;
    transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1), color 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.form-group label .help {
    margin-left: 8px;
}

.form-group .help {
    font-size: 12px;
    color: rgba(6, 18, 35, 0.67);
    letter-spacing: normal;
    line-height: 18px;
    display: block;
    margin-top: 6px;
    margin-left: 3px;
}

@keyframes shake {
    8%,
    41% {
        -webkit-transform: translateX(-4px);
    }
    25%,
    58% {
        -webkit-transform: translateX(4px);
    }
    75% {
        -webkit-transform: translateX(-2px);
    }
    92% {
        -webkit-transform: translateX(2px);
    }
    0%,
    100% {
        -webkit-transform: translateX(0);
    }
}

.form-group-default {
    background-color: #fff;
    font-family: -apple-system, BlinkMacSystemFont, 'Inter UI', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    position: relative;
    border: 1px solid rgba(6, 18, 35, 0.14);
    border-top-color: rgba(0, 0, 0, 0.14);
    border-radius: 2px;
    padding: 5px 9px 4px;
    overflow: hidden;
    width: 100%;
    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease;
    cursor: text;
}

.form-group-default.labeled {
    overflow: visible;
    margin: 20px 0 0 0;
    padding: 20px !important;
}

.form-group-default .group-label {
    position: absolute;
    top: -12px;
    left: 15px;
    padding: 0 5px;
    background-color: #ffffff;
    font-family: -apple-system, BlinkMacSystemFont, 'Inter UI', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 16px;
}

.form-group-default-react-select {
    background-color: #fff;
    font-family: -apple-system, BlinkMacSystemFont, 'Inter UI', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    position: relative;
    border: 1px solid rgba(6, 18, 35, 0.14);
    border-top-color: rgba(0, 0, 0, 0.14);
    border-radius: 2px;
    padding: 5px 9px 4px;
    overflow: hidden;
    width: 100%;
    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease;
}

.form-group-react-select {
    background-color: #fff;
    font-family: -apple-system, BlinkMacSystemFont, 'Inter UI', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    position: relative;
    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease;
    /*border-radius: 2px;
  padding: 5px 9px 4px;
  overflow: hidden;*/
    width: 100%;
}

.form-group-default.form-check-group {
    cursor: default;
}

.form-group-default.form-check-group label {
    cursor: pointer;
}

.form-group-default.required:after,
.form-group-react-select.required:after,
.form-group-default-react-select:after {
    color: #ea2c54;
    content: '*';
    font-family: -apple-system, BlinkMacSystemFont, 'Inter UI', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 20px;
    position: absolute;
    right: 10px;
    top: 2px;
}

.form-group-default.disabled,
.form-group-react-select.disabled,
.form-group-default-react-select.disabled {
    background: #f9f9fa;
    color: rgba(53, 63, 77, 0.23);
    cursor: not-allowed;
}

.form-group-default.disabled.focused {
    border: 1px solid #0c6baf;
}

.form-group-default.disabled.focused label {
    opacity: 1;
}

.form-group-default.disabled label {
    opacity: 0.57;
    cursor: not-allowed;
}

.form-group-default.focused,
.form-group-default-react-select.focused {
    border: 1px solid #0c6baf !important;
}

.form-group-default.focused label,
.form-group-default-react-select.focused label {
    opacity: 0.67;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
    font-size: 12px;
    letter-spacing: normal;
}

.form-group-default.has-error label,
.form-group-react-select.has-error label,
.form-group-default-react-select.has-error label {
    color: #c81a32;
    animation-name: shake;
    animation-duration: 0.4s;
    animation-iteration-count: 1;
}

/*.form-group-default-react-select label {
  position: absolute!important;
}*/

.form-group-default.has-success .form-control-feedback,
.form-group-default.has-error .form-control-feedback,
.form-group-default-react-select.has-error .form-control-feedback {
    display: none !important;
}

.form-group-default.has-success .form-control,
.form-group-default.has-success .form-control:focus,
.form-group-default.has-error .form-control,
.form-group-default.has-error .form-control:focus {
    border: none;
    box-shadow: none;
}

.form-group-default + .error,
.form-group-react-select + .error,
.form-group-default-react-select + .error {
    margin-top: -10px;
    padding: 3px 7px 2px 4px;
    display: flex;
    letter-spacing: normal;
    animation-name: show-msg;
    animation-duration: 450ms;
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    position: relative;
}

.form-group-default + .error:after,
.form-group-react-select + .error:after,
.form-group-default-react-select + .error:after {
    content: '';
    width: 0;
    position: absolute;
    height: 1px;
    background-color: #ea2c54;
    /* top: -1px; */
    right: 0;
    animation-name: show-line;
    animation-delay: 300ms;
    animation-duration: 350ms;
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    animation-fill-mode: forwards;
}

@keyframes show-msg {
    0% {
        height: 0;
        opacity: 0;
    }
    100% {
        height: 25px;
        opacity: 1;
    }
}

@keyframes show-line {
    0% {
        width: 0;
    }
    100% {
        width: 100%;
    }
}

.form-group-default.input-group {
    padding: 0;
}

.form-group-default.input-group > label {
    margin-top: 6px;
    padding-left: 12px;
}

.form-group-default.input-group > label.inline {
    margin-top: 6px;
    float: left;
}

.form-group-default.input-group > .form-control {
    margin-top: -2px;
    margin-bottom: 3px;
    padding-left: 12px;
}

.form-group-default.input-group label {
    margin-top: 6px;
    padding-left: 12px;
}

.form-group-default.input-group .form-input-group {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
}

.form-group-default.input-group .form-control {
    margin-top: -2px;
    margin-bottom: 3px;
    padding-left: 12px;
    width: 100%;
}

.form-group-default.input-group .input-group-text {
    border: 0;
    border-radius: 0;
    min-width: 50px;
}

.form-group-default.input-group .input-group-text i {
    width: 100%;
}

.form-group-default.input-group .btn {
    border-top: 0;
    border-bottom: 0;
}

.form-group-default .form-control {
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.form-group-default .form-control.error {
    color: #061223;
}

.form-group-default .form-control:focus {
    background: none;
}

.form-group-default .form-control:focus::-moz-placeholder {
    color: inherit;
    opacity: 0.77;
    font-weight: 400;
    font-family: -apple-system, BlinkMacSystemFont, 'Inter UI', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.form-group-default .form-control:focus:-ms-input-placeholder {
    color: inherit;
    opacity: 0.77;
    font-weight: 400;
    font-family: -apple-system, BlinkMacSystemFont, 'Inter UI', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.form-group-default .form-control:focus::-webkit-input-placeholder {
    color: inherit;
    opacity: 0.77;
    font-weight: 400;
    font-family: -apple-system, BlinkMacSystemFont, 'Inter UI', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.form-group-default .form-control:focus::placeholder {
    color: inherit;
    opacity: 0.77;
    font-weight: 400;
    font-family: -apple-system, BlinkMacSystemFont, 'Inter UI', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.form-group-default .form-control:-webkit-autofill,
.form-group-default .form-control.error:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #fff inset !important;
}

.form-group-default .form-control:-webkit-autofill:focus,
.form-group-default .form-control.error:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 1000px #fff inset !important;
}

.form-group-default textarea.form-control {
    padding-top: 5px;
}

.form-group-default label {
    margin: 0;
    display: block;
    opacity: 1;
    cursor: text;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
}

.form-group-default label.label-lg {
    font-size: 13px;
    left: 13px;
    top: 9px;
}

.form-group-default label.label-sm {
    font-size: 11px;
    left: 11px;
    top: 6px;
}

.form-group-default label.highlight {
    opacity: 1;
}

.form-group-default label.fade {
    opacity: 0.5;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
    font-size: 12px;
    letter-spacing: normal;
}

.form-group-default > .input-lg {
    height: 29px;
    min-height: 29px;
    padding-left: 1px;
}

.form-group-default > .input-sm {
    min-height: 18px;
    height: 18px;
}

.form-group-default.form-group-default-select {
    overflow: visible;
}

.form-group-default.form-group-default-select .ui-select-container.ui-select-bootstrap.dropdown .ui-select-match {
    padding-top: 6px;
    height: 23px;
}

.form-group-default.form-group-default-select .ui-select-container .select2-choices {
    border: 0;
}

.form-group-default.form-group-default-select2 {
    padding: 0;
}

.form-group-default.form-group-default-select2 > label {
    position: absolute;
    z-index: 10;
    padding: 5px 12px 0 9px;
    color: #656c77;
}

.form-group-default.form-group-default-select2 > label.label-lg {
    left: 0;
    top: 0;
}

.form-group-default.form-group-default-select2 > label.label-sm {
    left: 0;
    top: 0;
}

.form-group-default.form-group-default-select2 .select2-container .select2-selection--single {
    padding-top: 20px;
    height: 52px;
}

.form-group-default.form-group-default-select2 .select2-container .select2-selection--single .select2-arrow b:before {
    top: 20px;
}

.form-group-default.form-group-default-select2 .select2-container .select2-selection--single .select2-chosen {
    padding-left: 3px;
    padding-top: 1px;
}

.form-group-default.form-group-default-select2 .select2-container .select2-selection--multiple {
    padding-top: 19px;
    padding-bottom: 0;
    min-height: 52px;
    border: 0 !important;
}

.form-group-default.form-group-default-select2
    .select2-container
    .select2-selection--multiple
    .select2-selection__rendered
    .select2-selection__choice {
    margin-top: 5px;
}

.form-group-default.form-group-default-select2.input-lg {
    height: auto;
    padding: 0;
}

.form-group-default.form-group-default-select2.input-lg .select2-container .select2-selection--single {
    padding-top: 26px;
    height: 58px;
}

.form-group-default.form-group-default-select2.input-lg
    .select2-container
    .select2-selection--single
    .select2-selection__rendered {
    font-size: 18px;
}

.form-group-default.form-group-default-select2.input-sm {
    height: auto;
    padding: 0;
}

.form-group-default.form-group-default-select2.input-sm .select2-container .select2-selection--single {
    padding-top: 21px;
    height: 49px;
}

.form-group-default.form-group-default-select2.input-sm
    .select2-container
    .select2-selection--single
    .select2-selection__rendered {
    font-size: 13px;
}

.form-group-default.form-group-default-selectFx {
    padding: 0;
}

.form-group-default.form-group-default-selectFx > label {
    position: absolute;
    z-index: 10;
    padding: 7px 12px 0 12px;
}

.form-group-default.form-group-default-selectFx > label.label-lg {
    left: 0;
    top: 0;
}

.form-group-default.form-group-default-selectFx > label.label-sm {
    left: 0;
    top: 0;
}

.form-group-default.form-group-default-selectFx .cs-wrapper .cs-placeholder {
    padding-top: 28px;
    height: 52px;
    padding-left: 12px;
}

.form-group-default.form-group-default-selectFx .cs-wrapper .cs-select {
    height: auto;
}

.form-group-default.form-group-default-selectFx .cs-wrapper .cs-select > span:after,
.form-group-default.form-group-default-selectFx .cs-wrapper .cs-select .cs-selected span:after {
    top: 39px;
}

.form-group-default.form-group-default-selectFx .cs-wrapper .cs-select.input-lg .cs-placeholder {
    height: 60px;
}

.form-group-default.form-group-default-selectFx .cs-wrapper .cs-select.input-sm .cs-placeholder {
    height: 50px;
}

.form-group-default.form-group-default-selectFx .cs-wrapper .dropdown-placeholder {
    vertical-align: top;
}

.form-group-default .input-group-append .input-group-text:before {
    content: '';
    width: 1px;
    height: 80%;
    top: 0;
    left: -10px;
    position: relative;
    background-color: rgba(0, 0, 0, 0.16);
}

.form-group-default .input-group-prepend .input-group-text:before {
    content: '';
    width: 1px;
    height: 80%;
    top: 0;
    right: -10px;
    position: relative;
    background-color: rgba(0, 0, 0, 0.16);
}

/*  Activation & Reset code inputs
-------------------------------------------------*/
input[name*='activationCode'],
input[name*='resetCode'] {
    font-weight: 400;
    text-align: center;
    line-height: 22px;
    border-radius: 6px;
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    min-height: 48px;
    min-width: 48px;
}

input.has-error[name*='activationCode'],
input.has-error[name*='resetCode'] {
    border-color: #ea2c54;
    -webkit-box-shadow: none;
    box-shadow: none;
}

input.has-error[name*='activationCode']:focus,
input.has-error[name*='resetCode']:focus {
    border-color: #ce153c;
    -webkit-box-shadow: none;
    box-shadow: none;
}

/* Form validation
--------------------------------------------------
*/
.has-success .help-block,
.has-success .control-label,
.has-success .radio,
.has-success .checkbox,
.has-success .radio-inline,
.has-success .checkbox-inline {
    color: #0057bc;
}

.has-success .form-control {
    border-color: #0057bc;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.has-success .form-control:focus {
    border-color: #004089;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.has-success .form-control:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #fff inset, 0 0 0 1px rgba(0, 0, 0, 0.1), 0 -1px 0 0 rgba(0, 0, 0, 0.05) !important;
}

.has-success .input-group-addon {
    background: #f3f3f4;
    border: 1px solid rgba(6, 18, 35, 0.14);
    color: rgba(53, 63, 77, 0.47);
}

.has-success .form-control-feedback {
    color: #0057bc;
}

.has-warning .help-block,
.has-warning .control-label,
.has-warning .radio,
.has-warning .checkbox,
.has-warning .radio-inline,
.has-warning .checkbox-inline {
    color: #d8a94f;
}

.has-warning .form-control {
    border-color: #d8a94f;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.has-warning .form-control:focus {
    border-color: #c7932d;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.has-warning .form-control:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #fff inset, 0 0 0 1px rgba(0, 0, 0, 0.1), 0 -1px 0 0 rgba(0, 0, 0, 0.05) !important;
}

.has-warning .input-group-addon {
    background: #f3f3f4;
    border: 1px solid rgba(6, 18, 35, 0.14);
    color: rgba(53, 63, 77, 0.47);
}

.has-warning .form-control-feedback {
    color: #d8a94f;
}

.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline {
    color: #ea2c54;
}

.has-error .form-control {
    border-color: #ea2c54;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.has-error .form-control:focus {
    border-color: #ce153c;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.has-error .form-control:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #fff inset, 0 0 0 1px rgba(0, 0, 0, 0.1), 0 -1px 0 0 rgba(0, 0, 0, 0.05) !important;
}

.has-error .input-group-addon {
    background: #f3f3f4;
    border: 1px solid rgba(6, 18, 35, 0.14);
    color: rgba(53, 63, 77, 0.47);
}

.has-error .form-control-feedback {
    color: #ea2c54;
}

label.error {
    font-size: 12px;
    color: #c81a32;
    display: block;
}

.validation-popover {
    background: #000;
    box-shadow: rgba(0, 0, 0, 0.3) 0 1px 4px;
}

.validation-popover .popover-body {
    padding: 4px 8px;
    font-size: 12px;
    line-height: 1.4;
    color: #fff;
}

.validation-popover > .arrow::after {
    border-top-color: #000;
}

/* Number input */
.quantity {
    position: relative;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'] {
    -moz-appearance: textfield;
}

.quantity input {
    width: 75%;
    height: 42px;
    line-height: 1.65;
    float: left;
    display: block;
    margin: 0;
    padding: 0 0 0 20px;
    border: 1px solid #eee;
}

.quantity input:focus {
    outline: 0;
}

.quantity-nav {
    float: left;
    position: relative;
    height: 42px;
    width: 25%;
}

.quantity-button {
    position: relative;
    cursor: pointer;
    border-left: 1px solid #eee;
    width: 100%;
    text-align: center;
    color: #333;
    font-size: 13px;
    font-family: 'Trebuchet MS', Helvetica, sans-serif !important;
    line-height: 1.7;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.quantity-button.quantity-up.disabled,
.quantity-button.quantity-down.disabled {
    color: rgba(0, 0, 0, 0.15);
    cursor: not-allowed !important;
}

.quantity-button.quantity-up {
    position: absolute;
    height: 50%;
    top: 0;
    border-bottom: 1px solid #eee;
}

.quantity-button.quantity-down {
    position: absolute;
    bottom: -1px;
    height: 50%;
}

.quantity-button.quantity-up:hover,
.quantity-button.quantity-up:focus,
.quantity-button.quantity-up:active,
.quantity-button.quantity-down:hover,
.quantity-button.quantity-down:focus,
.quantity-button.quantity-down:active {
    background-color: #eee;
}

.form-group-default.numeric {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    min-width: 90px;
    height: 54px;
}

.form-group-default.numeric .button-container {
    height: 95%;
    width: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid rgba(0, 0, 0, 0.16);
    padding: 0 0 0 7px;
}

.form-group-default.numeric .buttons {
    height: inherit;
    width: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
}

.form-group-default.numeric .buttons .numeric-increase,
.form-group-default.numeric .buttons .numeric-decrease {
    cursor: pointer !important;
    color: rgba(33, 37, 41, 0.15);
}

.form-group-default.numeric .buttons .numeric-increase:hover,
.form-group-default.numeric .buttons .numeric-increase:active,
.form-group-default.numeric .buttons .numeric-increase:focus,
.form-group-default.numeric .buttons .numeric-decrease:hover,
.form-group-default.numeric .buttons .numeric-decrease:active,
.form-group-default.numeric .buttons .numeric-decrease:focus {
    color: rgba(33, 37, 41, 1);
}

.form-group-default.numeric .buttons .numeric-increase.disabled,
.form-group-default.numeric .buttons .numeric-decrease.disabled {
    color: rgba(0, 0, 0, 0.15);
    cursor: not-allowed !important;
    opacity: 0.45;
}

.form-group-default.numeric-input {
    background-color: transparent;
    border: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    min-width: 90px;
}

.form-group-default.numeric-input .numeric-step-button {
    width: 33.3333333333333%;
    height: 30px;
    font-size: 18px;
    background-color: transparent;
    padding: 3px 8px;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.65);
    font-weight: bold;
    border: 0;
}

.form-group-default.numeric-input .numeric-step-button:hover,
.form-group-default.numeric-input .numeric-step-button:active,
.form-group-default.numeric-input .numeric-step-button:focus {
    color: rgba(0, 0, 0, 1);
    text-decoration: none;
}

.form-group-default.numeric-input .controls {
    display: flex;
    background-color: transparent;
    align-items: center;
    justify-content: space-around;
    vertical-align: middle;
    border: 0;
    padding-bottom: 5px;
}

.form-group-default.numeric-input .controls input {
    background-color: transparent;
    text-align: center;
}

.input-group-append.numeric-steppers .numeric-step-button {
    height: 100%;
    padding: 0 8px 15px 8px;
    cursor: pointer;
}

.input-group-append.numeric-steppers .numeric-step-button:first-child {
    border-right: 1px solid #eee;
}

.input-group-append.numeric-steppers .numeric-step-button:hover,
.input-group-append.numeric-steppers .numeric-step-button:focus {
    background-color: #eee;
}

.input-group-append.numeric-steppers .numeric-step-button:active {
    color: #ffffff;
    background-color: #0c6baf;
}

.form-control {
    box-shadow: none !important;
}
