.btn {
    box-shadow: none !important;
    min-height: 45px !important;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    display: inline-block;
    position: relative;

    @keyframes button-spin {
        to {
            transform: rotate(359deg);
        }
    }

    .btn-content {
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
    }

    &.loading {
        pointer-events: none;
        opacity: 0.75;
        .btn-content {
            visibility: hidden;
            opacity: 0;
        }
        &::after {
            content: '';
            position: absolute;
            border-radius: 100%;
            width: 16px;
            height: 16px;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            border: 2px solid rgba(255, 255, 255, 0.5);
            border-left-color: #fff;
            border-top-color: #fff;
            animation: button-spin 0.35s infinite linear;
            opacity: 1;
        }
    }

    &.container-btn {
        padding: 0 !important;
        background-color: transparent !important;
        z-index: 1;
    }

    &.count-badge:after,
    &.cart-count:after {
        content: attr(data-qty);
        position: absolute;
        border-radius: 100%;
        height: 22px;
        width: 22px;
        background-color: #78b94c;
        border: 1px solid transparent;
        color: #fff;
        padding: -2px 0 2px 0;
        font-size: 0.85rem;
        font-weight: 300;
        top: 5px;
        right: 5px;
        z-index: 699;
    }

    &.counter-danger:after {
        background-color: #ea2c54;
    }

    &.counter-primary:after {
        background-color: #0973b9;
    }

    &.counter-simple:after {
        background-color: #353f4d;
    }

    &.btn-block {
        &.cart-count:after {
            right: 10%;
        }

        &.no-text.cart-count:after,
        &.cart-count:after {
            right: 35%;
            @media (max-width: 992px) {
                right: 25%;
            }
        }
    }

    &.btn-rounded {
        border-radius: 30px !important;
    }

    &.btn-circular {
        width: 45px;
        padding: 0 !important;
        border-radius: 50% !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &.btn-primary {
        color: #ffffff !important;
        background-color: #0973b9;
        border: 1px solid #0973b9 !important;

        i.custom-icon.icon {
            background-color: #fff !important;
        }

        &:hover,
        &:focus,
        &:active,
        &.inProcess {
            color: #ffffff !important;
            background-color: rgba(9, 115, 185, 0.85) !important;
            border: 1px solid rgba(9, 115, 185, 0.85) !important;

            i.custom-icon.icon {
                background-color: #ffffff !important;
            }
        }
    }

    &.btn-outline-primary {
        color: #0973b9;
        border: 1px solid #0973b9 !important;

        i.custom-icon.icon {
            background-color: #0973b9 !important;
        }

        &:hover,
        &:focus,
        &:active,
        &.inProcess {
            color: #ffffff;
            background-color: #0973b9;

            i.custom-icon.icon {
                background-color: #ffffff !important;
            }
        }
    }

    &.btn-success {
        color: #ffffff;
        background-color: #28a745;

        i.custom-icon.icon {
            background-color: #ffffff !important;
        }

        &:hover,
        &:focus,
        &:active,
        &.inProcess {
            color: #ffffff;
            background-color: rgba(40, 167, 69, 0.5);

            i.custom-icon.icon {
                background-color: #ffffff !important;
            }
        }
    }

    &.btn-outline-success {
        color: #28a745;

        i.custom-icon.icon {
            background-color: #28a745 !important;
        }

        &:hover,
        &:focus,
        &:active,
        &.inProcess {
            color: #ffffff;
            background-color: #28a745;

            i.custom-icon.icon {
                background-color: #ffffff !important;
            }
        }
    }

    &.btn-default {
        color: #353f4d;
        background-color: #ffffff !important;
        border: 1px solid rgba(53, 63, 77, 0.15);

        i.custom-icon.icon {
            background-color: #353f4d !important;
        }

        &:hover,
        &:focus,
        &:active,
        &.inProcess {
            color: #353f4d;
            border: 1px solid rgba(53, 63, 77, 0.1) !important;
            background-color: rgba(255, 255, 255, 0.5) !important;

            i.custom-icon.icon {
                background-color: #353f4d !important;
            }
        }

        &.loading::after {
            border: 2px solid rgba(51, 51, 51, 0.5);
            border-left-color: #333;
            border-top-color: #333;
        }
    }

    &.btn-danger {
        color: #ffffff;
        background-color: #dc3545 !important;
        border: 1px solid rgba(220, 53, 69, 0.15);

        i.custom-icon.icon {
            background-color: #ffffff !important;
        }

        &:hover,
        &:focus,
        &:active,
        &.inProcess {
            border: 1px solid rgba(220, 53, 69, 0.1) !important;
            background-color: rgba(220, 53, 69, 0.5) !important;
        }
    }

    &.btn-outline-danger {
        color: #dc3545;

        i.custom-icon.icon {
            background-color: #dc3545 !important;
        }

        &:hover,
        &:focus,
        &:active,
        &.inProcess {
            color: #ffffff;
            background-color: #dc3545;

            i.custom-icon.icon {
                background-color: #ffffff !important;
            }
        }
    }

    &:disabled {
        cursor: not-allowed;

        &.btn-success,
        &.btn-success:hover {
            color: #fefefe;
            background-color: rgba(40, 167, 69, 0.5);
            border-color: rgba(40, 167, 69, 0.5);

            i.custom-icon.icon {
                background-color: #fefefe !important;
            }
        }

        &.btn-danger,
        &.btn-danger:hover {
            color: #fefefe;
            background-color: rgba(220, 53, 69, 0.5);
            border-color: rgba(220, 53, 69, 0.5);

            i.custom-icon.icon {
                background-color: #fefefe !important;
            }
        }
    }
}
