.rs-divider {
    background-color: #949ba4;
}

.rs-divider-vertical {
    display: inline-block;
    height: 1em;
    width: 1px;
    vertical-align: middle;
    margin: 0 12px;
}

.rs-divider-horizontal {
    height: 1px;
    margin: 24px 0;
}

.rs-divider-horizontal.rs-divider-with-text-left,
.rs-divider-horizontal.rs-divider-with-text-right,
.rs-divider-horizontal.rs-divider-with-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: transparent;
    margin: 20px 0;
    display: table \9;
    text-align: center \9;
}

.rs-divider-horizontal.rs-divider-with-text-left {
    text-align: left;
}

.rs-divider-horizontal.rs-divider-with-text-right {
    text-align: right;
}

@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
    .rs-divider-horizontal.rs-divider-with-text {
        text-align: center \9;
    }
    .rs-divider-horizontal.rs-divider-with-text-left {
        text-align: left;
    }
    .rs-divider-horizontal.rs-divider-with-text-right {
        text-align: right;
    }
}

.rs-divider-horizontal.rs-divider-with-text-left:after,
.rs-divider-horizontal.rs-divider-with-text-right:before,
.rs-divider-horizontal.rs-divider-with-text:after,
.rs-divider-horizontal.rs-divider-with-text:before {
    content: '';
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    border-top: 1px solid #949ba4;
    position: relative \9;
    display: table-cell \9;
    top: 50% \9;
    width: 50% \9;
    -webkit-transform: translateY(50%) \9;
    transform: translateY(50%) \9;
}

@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
    .rs-divider-horizontal.rs-divider-with-text-left:after,
    .rs-divider-horizontal.rs-divider-with-text-right:before,
    .rs-divider-horizontal.rs-divider-with-text:after,
    .rs-divider-horizontal.rs-divider-with-text:before {
        position: relative \9;
        top: 50% \9;
        width: 50%;
        -webkit-transform: translateY(50%) \9;
        transform: translateY(50%) \9;
    }
}

.rs-divider-inner-text {
    padding: 0 12px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
}

.rs-divider-horizontal.rs-divider-with-text-left .rs-divider-inner-text {
    padding-left: 0;
}

.rs-divider-horizontal.rs-divider-with-text-right .rs-divider-inner-text {
    padding-right: 0;
}

$borderColor: #949ba4;

.vertical-divider {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;

    height: 100%;
    width: 100%;

    .center-element {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;

        &:before,
        &:after {
            position: absolute;
            content: '';
            width: 1px;
            left: 50%;
            border-left: 1px solid $borderColor;
        }

        &:before {
            bottom: 50%;
            top: 0;
            margin-bottom: 12px;
        }

        &:after {
            top: 50%;
            bottom: 0;
            margin-top: 12px;
        }
    }
}
